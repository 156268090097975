import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DynamicStars from 'src/components/shared/DynamicStars'
import ThumbsUp from 'src/assets/img/icons/thumbs-up.svg'
import ThumbsDown from 'src/assets/img/icons/thumbs-down.svg'
import { P, MonoP } from 'src/components/shared/text'
import { VerifiedPurchaseBadge } from 'src/components/shared/badges'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

interface CustomerReviewProps {
  review: {
    id: number
    rating: number
    body: string
    created_at: string
    customer: {
      first_name: string
      last_name: string
    }
    up_vote_count: number
    down_vote_count: number
    photo_urls: string[]
  }
  vote: () => void
}
const SlateMonoP = styled(MonoP)`
  color: ${colors.slate};
`

const SlateP = styled(P)`
  color: ${colors.slate};
`

const CustomerName = styled(SlateMonoP)`
  margin-left: 16px;
`

const StarsAndName = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`

const TopRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

const ReviewBody = styled.div`
  padding: 24px 0;
  ${media.tablet`
    padding: 20px 0 10px;
  `};
`

const ReviewContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 20px;
  max-width: 1234px;
  border-top: 1px solid ${colors.lightText};
  padding: 42px 0;
  ${media.tablet`
    grid-template-columns: 6fr 4fr;
    padding: 36px 0;
  `};
`
const ImageContainer = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  margin: 0 -16px;
  width: calc(100% + 16px);
  overflow-x: auto;
  & img {
    margin: 0 16px;
  }
`
const TextContainer = styled.div`
  // max-width: 700px;
`

const DesktopReviewDate = styled.div`
  padding-right: 20px;
  display: none;
  ${media.tablet`
    display: inline-block;
  `}
`

const HideMobile = styled.div`
  display: none;
  ${media.tablet`
    display: inline-block;
  `}
`

const ThumbsIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`

const BottomRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  ${media.mobile`
    & img:not(:first-of-type){
      margin-left: 20px;
    }
  `}
  ${media.tablet`
    & img {
      margin-left: 20px;
    }
  `}
`

const ShowDesktop = styled.div`
  display: none;
  ${media.desktop`
    display: inline-block;
  `}
`

const ReviewImage = styled.img`
  max-height: 260px;
`

const CustomerReview: React.FC<CustomerReviewProps> = ({ review, vote }) => {
  const {
    id,
    rating,
    created_at,
    body,
    up_vote_count,
    down_vote_count,
    photo_urls,
    customer: { first_name, last_name }
  } = review

  const reviewDate = moment(created_at).format('M/D/YYYY')

  return (
    <ReviewContainer>
      <TextContainer>
        <TopRow>
          <StarsAndName>
            <DynamicStars fontSize={24} color={colors.orange} rating={rating} />
            <CustomerName>
              {first_name} {last_name}
            </CustomerName>
            <ShowDesktop>
              <VerifiedPurchaseBadge />
            </ShowDesktop>
          </StarsAndName>
          <DesktopReviewDate>
            <SlateMonoP>{reviewDate}</SlateMonoP>
          </DesktopReviewDate>
        </TopRow>

        <ReviewBody>
          <P>{body}</P>
        </ReviewBody>
        {/* <BottomRow>
          <HideMobile>
            <SlateP>Was this review helpful?</SlateP>
          </HideMobile>
          <ThumbsIcon src={ThumbsUp} alt="thumbs-up" onClick={() => vote(id, 'up_vote')} />
          <SlateP>{up_vote_count}</SlateP>
          <ThumbsIcon src={ThumbsDown} alt="thumbs-down" onClick={() => vote(id, 'down_vote')} />
          <SlateP>{down_vote_count}</SlateP>
        </BottomRow> */}
      </TextContainer>
      <ImageContainer>
        {photo_urls.map(photo => (
          <ReviewImage key={photo.large} src={photo.large} alt="Product Review Photo" />
        ))}
      </ImageContainer>
    </ReviewContainer>
  )
}

export default CustomerReview
