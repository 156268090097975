import React, { useEffect } from 'react'
import styled from 'styled-components'
import { gsap, Power3 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'

import DropHeader from '../shared/DropHeader'
import { H6, P } from '../shared/text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import Checkmark from '../../assets/img/icons/checkmark.svg'

const BackgroundColorWrapper = styled.section`
  width: 100%;
  height: fit-content;
  background-color: ${colors.lightText};
`

const Container = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;

  ${media.mobile`
    padding: 0 20px 60px 20px;
    justify-content: center;
    max-width: 370px;
  `}
  ${media.tablet`
    padding: 0 80px 80px 80px;
    max-width: none;
  `}
  ${media.desktop`
    padding: 0 60px 100px 60px;
  `}
`

const Grid = styled.div`
  display: grid;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    margin-top: 50px;
  `}
  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 80px 60px;
    margin-top: 80px;
    max-width: 1000px;
  `}
`

const TextGroup = styled.div`
  width: 100%;
  display: grid;
  opacity: 0;

  ${media.mobile`
    place-items: center;
    text-align: center;
    grid-column-gap: 16px;
    grid-row-gap: 20px;
  `}
  ${media.desktop`
    place-items: start;
    text-align: left;
    grid-template-rows: min-content 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 26px;
  `}
`

const HeaderGrid = styled.div`
  width: fit-content;

  display: grid;
  align-items: center;

  ${media.mobile`
    grid-template-columns: 40px min-content;
    grid-column-gap: 16px;
    margin-left: -56px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    margin-left: 0;
  `}
`

const COPY_MAP = {
  'allergy-and-itch': [
    {
      header: 'NASC Certified',
      body:
        'We are fully certified with the National Animal Supplement Council, and manufacture exclusively with NASC-certified Preferred Suppliers.'
    },
    {
      header: 'Clean Ingredients, No Artificial Fillers',
      body:
        'We use clean binding agents like organic honey and avoid artificial fillers like cellulose powder, palm oil, or gumming agents.'
    },
    {
      header: 'Sustainably Driven',
      body:
        'Our love for dogs extends to Mother Earth too. Our soft chews are packaged in beautiful eco-friendly tins recyclable into perpetuity, and are consciously formulated with sustainable ingredients.'
    },
    {
      header: 'Maximum Nutrition',
      body:
        'Our soft chews are made through cold-press extrusion, using very low temperatures to preserve maximum nutrient potency in each chew.'
    }
  ],
  default: [
    {
      header: 'Safety-First Manufacturing',
      body:
        'We manufacture our soft chews in a cGMP facility that is FDA-registered and certified with the National Animal Supplement Council (NASC).'
    },
    {
      header: 'Quality Ingredients',
      body:
        'We made room for more active ingredients by eliminating cheap fillers and harmful additives. Our soft chews contain no cellulose powder, silicon dioxide, maltodextrin, corn, soy, or wheat.'
    },
    {
      header: 'Tested & Approved',
      body:
        'Every batch of our soft chews is lab-tested before leaving the facility, and has a unique code that tracks ingredient supplier, manufacturing shift, testing, and processing.'
    },
    {
      header: 'Maximum Benefits',
      body:
        'Our soft chews are cooked through cold-press extrusion, using very low temperatures to preserve maximum nutrient potency in each chew.'
    }
  ]
}

const HowWereDifferent = ({ slug }) => {
  const copy = COPY_MAP[slug] || COPY_MAP.default

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const from = { opacity: 0, y: 50 }
    const to = { opacity: 1, y: 0, duration: 0.75, ease: Power3.easeOut }
    gsap.fromTo('.HowWereDifferent_Grid_0', from, {
      ...to,
      delay: 0,
      scrollTrigger: '.HowWereDifferent_Grid_0'
    })
    gsap.fromTo('.HowWereDifferent_Grid_1', from, {
      ...to,
      delay: 0.2,
      scrollTrigger: '.HowWereDifferent_Grid_0'
    })
    gsap.fromTo('.HowWereDifferent_Grid_2', from, {
      ...to,
      delay: 0.3,
      scrollTrigger: '.HowWereDifferent_Grid_3'
    })
    gsap.fromTo('.HowWereDifferent_Grid_3', from, {
      ...to,
      delay: 0.4,
      scrollTrigger: '.HowWereDifferent_Grid_3'
    })
  }, [])

  return (
    <BackgroundColorWrapper>
      <Container>
        <DropHeader title="How We’re Different" titleTablet={`How We’re\nDifferent`} />
        <Grid>
          {copy.map((item, idx) => (
            <TextGroup key={item.header} className={`HowWereDifferent_Grid_${idx}`}>
              <HeaderGrid>
                <img src={Checkmark} alt="Checkmark icon" />
                <H6>{item.header}</H6>
              </HeaderGrid>
              <P>{item.body}</P>
            </TextGroup>
          ))}
        </Grid>
      </Container>
    </BackgroundColorWrapper>
  )
}

export default HowWereDifferent
