import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { LargeP, P, H1 } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import { Section } from './ProductDetail'
import Img1 from 'src/data/products/images/puppy-breath-candle/img1.png'
import Img2 from 'src/data/products/images/puppy-breath-candle/img2.png'
import Img3 from 'src/data/products/images/puppy-breath-candle/img3.png'
import Stars from 'src/data/products/images/puppy-breath-candle/stars.svg'
import Doggy from 'src/data/products/images/puppy-breath-candle/dog.png'
import Stats from 'src/data/products/images/puppy-breath-candle/stats.png'



const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 0;
  `}
  ${media.tablet`
    padding: 86px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px;
    max-width: 100%;
  `}

  .wrapper {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    ${media.desktop`
      max-width: 965px;
    `}

    p {

      ${media.mobile`
        font-size: 18px;
        line-height: 1.2;
        padding: 20px;
      `}
      ${media.tablet`
        font-size: 24px;
      `}
      ${media.desktop`
        font-size: 24px;
        padding: 40px 0;
      `}
    }

    &.white {
      p {
        color: #fff;
      }
    }
  }
`

const Header = styled(H1)<{ textColor: string }>`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  color: ${(props: { textColor: string }) => (props.textColor ? props.textColor : colors.navy)};
  ${media.mobile`
    max-width: 300px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1334px;
  `}
`
const Grid = styled('div')<{ cardCols: number }>`
  width: 100%;
  display: grid;
  place-items: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-top: 50px;
    padding: 0 20px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr;
    margin-top: 0px;
  `}
  ${media.desktop`
    max-width: ${(props: { cardCols: number }) => (props.cardCols === 2 ? '900px' : '1305px')};
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 61px;
    margin-top: 0px;
    grid-template-rows: auto;
  `}
`

const TwoCol = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-top: 50px;
    .stats {
      order: -1;
      padding: 0 80px;
    }
  `}
  ${media.tablet`
    grid-template-columns: 1fr;
    margin-top: 0px;
    .stats {
      order: 1;
      padding: 0 140px;
    }
  `}
  ${media.desktop`
    max-width: ${(props: { cardCols: number }) => (props.cardCols === 2 ? '900px' : '1305px')};
    grid-template-columns: 1fr 1fr;
    grid-gap: 61px;
    margin-top: 0px;
    grid-template-rows: auto;
    .stats {
      order: 1;
      padding: 0 120px;
    }
  `}
`

const Card = styled('div')<{ tallCard?: boolean }>`
  overflow: hidden;
  position: relative;

  background-color: ${colors.white};
  color: ${colors.navy};
  border-radius: 25px;

  display: flex;
  align-items: center;

  width: 100%;

  ${media.mobile`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '500px' : '450px')};
  `}
  ${media.tablet`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '602px' : '510px')};
  `}
  ${media.desktop`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '655px' : '453px')};
  `}
`

const CardContent = styled.div`
  width: 100%;
  height: 100%;
`

const CopyContainer = styled('div')`
  height: 100%;
  padding: 30px;
`

const CardWrapper = styled.div`
  padding: 0 20px;
`

const BoldP = styled(P)`
  font-family: ${fonts.larsseit.medium};
  ${media.mobile`
    margin-bottom: 6px;
  `}
  ${media.desktop`
    margin-bottom: 0;
  `}
`

const BoldLargeP = styled(LargeP)`
  font-family: ${fonts.athletics.medium};
  margin-bottom: 13px;
  text-align: center;

  ${media.mobile`
    font-size: 36px;
    line-height: 1;
  `}
  ${media.desktop`
    font-size: 36px;
    line-height: 1;
  `}

`
const BodyP = styled.p`
  font-family: ${fonts.larsseit.regular};
  font-size: 16px;
  line-height: 20px;
`

const BodyPname = styled.p`
  font-family: ${fonts.monosten.normal};
  font-size: 14px;
  line-height:1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  justify-content: center;
  margin: 0;
  gap: 15px;
  padding-top: 30px;

  img {
    transform: translateY(-2px);
  }
`


const PupCandle: React.FC = () => {


  return (
    <>
      <Section bg={"navy"}>
        <Container>
          <Header textColor={"white"}>{"A Candle For\nDog Lovers"}</Header>
          <div className="wrapper white">
            <P>Inspired by the pure joy of a puppy's first nuzzle, we crafted this candle to capture those fleeting moments of innocence and warmth.</P>
          </div>
          <Grid>
            <img src={Img1} />
            <img src={Img2} />
            <img src={Img3} />
          </Grid>
        </Container>
      </Section>
      <Section bg={"lightBlue"}>
        <Container>
          <Grid>
            <Card>
              <CopyContainer>
                <BoldLargeP>“Never thought a scent could capture such memories. This candle does. Takes me right back to Bella's puppy days.”</BoldLargeP>
                <BodyPname><img src={Stars} /> Dave K.</BodyPname>
              </CopyContainer>
            </Card>
            <Card>
              <CopyContainer>
                <BoldLargeP>“Love the design! After the candle was done, I planted a small succulent in the vessel. Now, it's a double dose of joy.”</BoldLargeP>
                <BodyPname><img src={Stars} /> JASMINE R.</BodyPname>
              </CopyContainer>
            </Card>
            <Card>
              <CopyContainer>
                <BoldLargeP>“Bought two. One for me and one for my sister. We both got puppies last summer. The nostalgia hits every time.”</BoldLargeP>
                <BodyPname><img src={Stars} /> CARLOS F.</BodyPname>
              </CopyContainer>
            </Card>
          </Grid>
        </Container>
      </Section>
      <Section bg={"white"}>
        <Container>
          <Header><span dangerouslySetInnerHTML={{
          __html:`Smells like <u>Finn</u>`}} /></Header>
          <div className="wrapper">
            <P>Uplifting notes of lemon, bergamot, and mandarin channel puppy playfulness. Delicate florals capture puppy affection, while ambergris and sandalwood reflect our timeless bond.</P>
          </div>
          <TwoCol>
            <img src={Doggy}/>
            <div className="stats">
              <img src={Stats}/>
            </div>
          </TwoCol>
        </Container>
      </Section>
    </>
  )
}

export default PupCandle
