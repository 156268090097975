import React from 'react'
import styled from 'styled-components'

import SubscriptionDropdown from './SubscriptionDropdown'
import PackSelection from './PackSelection'
import { SmallP } from 'src/components/shared/text'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'

import { IRadioRowProps } from 'src/types/pdp'

const RadioInner = styled.div`
  border-radius: 50%;
  background-color: ${colors.navy};
  height: 12px;
  width: 12px;
`

const RadioOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid;
  border-radius: 50%;
  background-color: ${colors.white};
  height: 18px;
  width: 18px;

  margin: -3px 9px 0 0;
`

const RadioLabel = styled.p`
  margin: 0;

  font-family: ${fonts.athletics.medium};
  line-height: 24px;
  text-align: left;

  white-space: nowrap;

  ${media.mobile`
    font-size: 20px;
  `}
  ${media.desktop`
    font-size: 23px;
  `}
`

const MainRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
`

const Container = styled('div')<{ active: boolean; isBundle?: boolean }>`
  cursor: pointer;

  width: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${(props) => (props.active ? '#EBEFF8' : colors.white)};
  border-radius: 10px;

  color: ${(props) => (props.active ? colors.navy : colors.slate)};

  ${media.mobile`
    padding: 16px 9px 12px 12px;
    max-width: 350px;
    margin: 0 auto;
  `}
  ${media.tablet`
    margin: 0;
    max-width: 400px;
  `}
  ${media.desktop`
    padding: ${(props) => (props.active ? '17px 18px 17px 16px' : '17px 12px 17px 16px')};
    max-width: 490px;
  `}


  & ${RadioInner} {
    display: ${(props) => (props.active ? 'inline' : 'none')};
  }
  & ${RadioOuter} {
    border-color: ${(props) => (props.active ? colors.navy : colors.slate)};
  }
  & ${RadioLabel} {
    color: ${(props) => (props.active ? colors.navy : '#4c4c4c')};
  }
  & ${MainRow} {
    margin-bottom: ${(props) => (props.active ? '10px' : '0')};
    ${media.mobile`
      height: ${(props) => (props.active && props.isBundle ? '58px' : '36px')};
    `}
    ${media.desktop`
      height: ${(props) => (props.active ? '30px' : 'auto')};
    `}
  }

  &:hover {
    & ${RadioInner} {
      display: inline;
      background-color: ${(props) => (props.active ? colors.navy : colors.slate)};
    }
  }
`

const RadioSelector = styled.div`
  width: auto;
  display: flex;
  align-items: center;
`

const PricingBadge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const PricingDetails = styled.div`
  width: fit-content;

  display: grid;
  justify-content: end;
  justify-self: end;

  ${media.desktop`
    grid-template-columns: repeat(2, min-content);
    grid-column-gap: 6px;
  `}
`

const SavingsText = styled(SmallP)<{ isBundle?: boolean }>`
  font-family: ${fonts.larsseit.medium};
  color: ${colors.orange};
  text-align: right;
  line-height: 16px;

  padding-top: 6px;

  ${media.mobile`
    white-space: ${(props) => (props.isBundle ? 'nowrap' : 'pre-line')};
  `}
  ${media.desktop`
    line-height: 18px;
    white-space: nowrap;
  `}
`

const CrossedOutPrice = styled.p`
  color: ${colors.slate};
  font-size: 18px;
  line-height: 26px;
  font-family: ${fonts.athletics.medium};
  text-decoration: line-through;
  margin: 0;
  text-align: right;
`

const RadioRow: React.FC<IRadioRowProps> = (props) => {
  const {
    isBundle,
    active,
    isSubscription,
    label,
    crossedOutPrice,
    price,
    savingsDescription,
    otpOptions,
    packQuantity,
    frequency,
    setFrequency,
    activeVariant,
    onRadioClick,
    clickQuantityButton,
    slug
  } = props

  const handleRadioClick = () => {
    if (!active) onRadioClick(Boolean(isSubscription))
  }

  return (
    <Container isBundle={isBundle} active={active} onClick={handleRadioClick}>
      <MainRow>
        <RadioSelector>
          <RadioOuter>
            <RadioInner />
          </RadioOuter>
          <RadioLabel>{label}</RadioLabel>
        </RadioSelector>

        {active && (
          <PricingBadge>
            <PricingDetails>
              <CrossedOutPrice>{crossedOutPrice && `$${crossedOutPrice}`}</CrossedOutPrice>
              <RadioLabel>${price.toFixed(2)}</RadioLabel>
            </PricingDetails>

            {isSubscription && <SavingsText>{savingsDescription}</SavingsText>}

            {!isSubscription && (
              <SavingsText isBundle={isBundle}>
                {savingsDescription}
                {(isBundle || packQuantity > 1) && ` +\n Free Shipping`}
              </SavingsText>
            )}
          </PricingBadge>
        )}
      </MainRow>

      {active && !isSubscription && (
        <PackSelection
          otpOptions={otpOptions}
          activeVariant={activeVariant}
          clickQuantityButton={clickQuantityButton}
        />
      )}

      {active && isSubscription && (
        <SubscriptionDropdown slug={slug} frequency={frequency} setFrequency={setFrequency} />
      )}
    </Container>
  )
}

export default RadioRow
