import React from 'react'
import styled from 'styled-components'

import { LargeP, MonoP, SmallP } from 'src/components/shared/text'
import { fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'
import { ISubscriptionGuideProps } from 'src/types/pdp'

const Container = styled('div')<{ bg: string; color: string; visible: boolean }>`
  border-radius: 18px;
  padding: 20px 30px 40px 30px;
  position: absolute;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  height: fit-content;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  z-index: 100;
  transform: translate(-50%, -50%);
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.06));
  ${media.mobile`
    width: 95vw;
    max-height: 240px;
    right: -45%;
    bottom: -25%;
  `}
  ${media.tablet`
    min-height: 232px;
    max-height: 400px;
    width: fit-content;
    right: -14%;
    top: 40%;
    bottom: auto;
  `}
`

const Title = styled(LargeP)`
  font-family: ${fonts.athletics.medium};
  margin-bottom: 30px;
`

const GridRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-column-gap: 30px;
  border-bottom: 1px solid ${(props) => props.color};
  padding: 3px 0;
  &:first-of-type {
    padding: 0 0 12px 0;
  }
  &:last-of-type {
    border: none;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 44px;
  width: 44px;
  display: grid;
  place-items: center;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.color};
  border-radius: 50%;

  ${media.desktop`
    display: none;
  `}
`

const SubscriptionGuide: React.FC<ISubscriptionGuideProps> = (props) => {
  const { onClose, bg, color } = props
  const rows = [
    { weight: '1 - 25 lbs', amount: '1 chew', frequency: '90 Days' },
    { weight: '26 - 50 lbs', amount: '2 chews', frequency: '45 Days' },
    { weight: '51 - 75 lbs', amount: '3 chews', frequency: '30 Days' },
    { weight: 'Over 75 lbs', amount: '4 chews', frequency: '21 Days' }
  ]

  return (
    <Container {...props}>
      <CloseButton onClick={onClose}>×</CloseButton>

      <Title>Subscription Guide</Title>

      <GridRow {...props}>
        <MonoP>DOG'S WEIGHT</MonoP>
        <MonoP>DAILY AMOUNT</MonoP>
        <MonoP>FREQUENCY</MonoP>
      </GridRow>

      {rows.map((row) => (
        <GridRow key={row.weight} {...props}>
          <SmallP>{row.weight}</SmallP>
          <SmallP>{row.amount}</SmallP>
          <SmallP>{row.frequency}</SmallP>
        </GridRow>
      ))}
    </Container>
  )
}

export default SubscriptionGuide
