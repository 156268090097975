import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from './buttons'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'

const ArrowIconRight = styled(ArrowRightOutlined)`
  color: ${colors.navy};
  font-size: 24px;
  cursor: pointer;
  transition: 250ms transform, color ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
  ${media.mobile`
    display:none;
  `}
  ${media.desktop`
    display: block;
  `}
`

const ArrowIconLeft = styled(ArrowLeftOutlined)`
  color: ${colors.navy};
  font-size: 24px;
  cursor: pointer;
  transition: 250ms transform, color ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
  ${media.mobile`
    display:none;
  `}
  ${media.desktop`
    display: block;
  `}
`

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  ${media.mobile`
    width: 100%;
    flex-direction: column;
    padding: 30px 20px 60px;
    button {
      width: 100%;
    }
  `}
  ${media.desktop`
    width: auto;
    flex-direction: row;
    padding: 60px 0;
    button {
      width: fit-content;
    }
  `}
`

export const ProductSwap = ({bundles = [], activeIndex = 0, setActiveBundle}) => {
  if (bundles.length === 0) return (<></>);
  let buttonsLength = 0;
  return (
    <Wrapper>
      <ArrowIconLeft onClick={() => {
        if (activeIndex > 0) {
          setActiveBundle(activeIndex - 1);
        }
      }}/>
      {bundles.map((bundle, idx) => {
        buttonsLength = idx
        if(activeIndex === idx) {
          return (
            <PrimaryButton
              noScale
              key={bundle.title}
              copy={bundle.title}
              onClick={() => setActiveBundle(idx)}
            />
          )
        }
        return (
          <SecondaryButton
            noScale
            key={bundle.title}
            copy={bundle.title}
            onClick={() => setActiveBundle(idx)}
          />
        )
      })}
      <ArrowIconRight onClick={() => {

        if (activeIndex < buttonsLength) {
          setActiveBundle(activeIndex + 1);
        }
      }}/>
    </Wrapper>
  )
}
