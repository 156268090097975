import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { Menu, Dropdown } from 'antd'

import { DownOutlined } from '@ant-design/icons'
import { reviewItems } from 'src/hooks/useReviews'

import CustomerReview from './CustomerReview'
import TotalReviewsAndRating from './TotalReviewsAndRating'
import DropHeader from '../shared/DropHeader'
import { P, SmallP } from '../shared/text'
import FilterTag from '../shared/FilterTag'
import { fonts, colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  height: fit-content;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${media.mobile`
    padding: 40px 28px 50px 31px;
  `}
  ${media.tablet`
    padding: 40px 40px 70px 40px;
  `}
  ${media.desktop`
    padding: 70px 40px 70px 40px;
  `}
`

const CustomDropHeader = styled(DropHeader)`
  padding: 40px 0 20px;
  ${media.tablet`
    padding: 15px 0 32px;
  `}
  ${media.desktop`
    padding: 15px 0 32px;
  `}
`
const ReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1234px;
  border-top: 1px solid ${colors.lightText};
  padding-top: 19px;
  padding-bottom: 19px;
`

const NavigationLink = styled.p`
  font-family: ${fonts.monosten.normal};
  text-transform: uppercase;
  color: inherit;
  font-size: 16px;
  cursor: pointer;
`

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1300px;
  margin-bottom: 37px;
  flex-wrap: wrap;
`

const FilterDropdown = styled.a`
  background-color: ${colors.lightText};
  padding: 13px 16px 13px 22px;
  border-radius: 50px;
  font-size: 18px;
  line-height: 18px;
  margin: 10px 20px 10px 0;
`

const Legalese = styled(P)`
  color: #161345;
  padding: 0;
  max-width: 1234px;
  font-size: 14px;
  text-align: left;
  font-style: italic;
`

const DropdownP = styled(P)`
  color: ${colors.navy};
`

const scrollToRef = (ref: any) => {
  const totalY = ref.current.offsetTop + ref.current.parentElement.offsetTop
  window.scrollTo(0, totalY - 75) // 75px for the height of sticky header navbar
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

const Reviews: React.FC<any> = ({ reviewsHook, title }) => {
  const {
    reviews,
    reviewStats,
    pagePointers,
    reviewFilters,
    filterProduct,
    vote,
    fetchReviews,
    reviewProducts,
    loading
  } = reviewsHook
  const reviewsContainerRef = useRef(null)

  useEffect(() => {
      const key = getKeyByValue(reviewItems, title);
      if (key) {
        reviewFilters[`${key}`];
        filterProduct({ key: key });
      }
  }, [])

  async function getReviews(direction: string) {
    await fetchReviews(direction)
    scrollToRef(reviewsContainerRef)
  }

  const menu = (
    <Menu
      onClick={filterProduct}
      style={{
        backgroundColor: colors.lightText,
        borderRadius: '20px'
      }}
    >
      {Object.keys(reviewProducts).map((key) => (
        <Menu.Item key={key}>
          <DropdownP>{reviewProducts[key]}</DropdownP>
        </Menu.Item>
      ))}
    </Menu>
  )

  if (loading) return null
  return (
    <Section id="reviews">
      <TotalReviewsAndRating reviewStats={reviewStats} />
      <CustomDropHeader title="Worth Barking About" />
      {/* <FilterContainer ref={reviewsContainerRef}>
        <Dropdown overlay={menu} trigger={['click']}>
          <FilterDropdown className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            Filter by Product <DownOutlined />
          </FilterDropdown>
        </Dropdown>
        {Object.keys(reviewFilters).map((productId) => (
          <FilterTag
            key={productId}
            text={reviewFilters[productId]}
            id={productId}
            clickHandler={filterProduct}
          />
        ))}
      </FilterContainer> */}

      <ReviewsContainer>
        {reviews.map((review) => (
          <CustomerReview review={review} key={review.id} vote={vote} />
        ))}
      </ReviewsContainer>
      <NavigationContainer>
        <div>
          {Boolean(pagePointers.before) && (
            <NavigationLink onClick={() => getReviews('before')}>Previous</NavigationLink>
          )}
        </div>
        <div>
          {Boolean(pagePointers.after) && (
            <NavigationLink onClick={() => getReviews('after')}>Next</NavigationLink>
          )}
        </div>
      </NavigationContainer>
      <Legalese>*These product reviews are for informational purposes only. The information is not a substitute for expert veterinary care. Testimonials are written by actual customers and represent their own observations. These observations are not guaranteed, are not medically substantiated, and may not be typical for other pets.</Legalese>
    </Section>
  )
}

export default Reviews
