const determineSavings = (savingsData: any) => {
  const { productOptions, activeVariant, isSubscription, packQuantity } = savingsData
  const { oneTime, subscription } = productOptions.single.pricing

  const singlePrice = oneTime.price

  const compareAtPrice = oneTime.compareAtPrice
  const comparableDiscount = compareAtPrice - singlePrice

  const compareMultipackPrice = singlePrice * packQuantity
  const subPercentageDiscount = subscription.percentageDiscount

  // Subscriptions
  if (isSubscription) {
    return [`Save ${subPercentageDiscount}`, null]
  }

  // Bundles
  if (comparableDiscount > 0) {
    return [`Save $${comparableDiscount.toFixed(0)}`, compareAtPrice.toFixed(2)]
  }

  // Multi-packs
  if (packQuantity > 1) {
    const multiPackSavings = compareMultipackPrice - activeVariant.pricing.oneTime.price
    return [`Save $${multiPackSavings.toFixed(0)}`, compareMultipackPrice.toFixed(2)]
  }

  // Basic 1-pack OTP
  return [null, null]
}

export default determineSavings
