import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { SUBSCRIPTION_FREQUENCIES } from 'src/utils/globalVariables'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import CtaButtons from './AboveTheFold/CtaButtons'
import { addVariantToCart } from 'src/actions/checkout'
import { trackAddToCartEvent } from 'src/utils/event-tracking'
import useCart from 'src/hooks/useCart'

const Section = styled.section<{ bg: string }>`
  width: 100%;
  background-color: ${(props) => colors[props.bg]};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 35px;
  `}
  ${media.tablet`
    padding: 86px 0;
  `}
  ${media.desktop`
    padding: 86px 0;
    max-width: 1350px;
  `}

  hgroup {
    max-width: 1200px;
    text-align: center;
    margin: 0 auto 36px;
    padding: 0 30px;
    white-space: pre-line;
    color: ${colors.navy};

    h1 {
      max-width: 800px;
      margin: 0 auto;
    }

    p {
      padding: 20px 0 0 0;
    }
  }
`

const ProductCard = styled.div<{ isBundle: boolean }>`
  background-color: #ffffff;
  border-radius: 30px;
  overflow: hidden;
  backface-visibility: hidden;
  width: 100%;
  display: flex;
  ${(props) =>
    props.isBundle &&
    `
    justify-content: end;
    height: 100%;
  `};
  justify-content: end;
  flex-direction: column;
`

const ProductCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 40px 30px;
  gap: 25px;

  ${media.mobile`
    padding: 40px 20px 20px;
  `}
  ${media.tablet`
    padding: 40px 40px 30px;
  `}
  ${media.desktop`
    padding: 40px 40px 30px;
  `}

  ul {
    padding: 20px 0;
    margin: 0;
    li {
      font-family: ${fonts.larsseit.normal};
      color: ${colors.navy};
      font-size: 18px;
    }
  }
`

const ProductContainer = styled.div<{ isBundle: boolean }>`
  display: grid;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  ${(props) =>
    props.isBundle &&
    `
    height: 100%;
  `};
  grid-gap: 25px;

  ${media.mobile`
    grid-template-columns: 1fr;
  `}

  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${media.desktop`
    justify-content: flex-start;
    grid-template-columns: ${(props) => (!props.isBundle ? '1fr 1fr 1fr' : '1fr 1fr')};
  `}
`

const GreyText = styled(P)<{ margin: string }>`
  font-size: 16px;
  color: ${colors.slate};
  text-transform: lowercase;
  ${media.desktop`
    margin: ${(props) => props.margin};
  `}
`

const Tag = styled.div<{ bg: string; isBundle: boolean }>`
  background-color: ${(props) => (props.isBundle ? colors[props.bg] : colors?.lightPurple)};
  text-align: center;
  padding: 13px 0;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.navy};
  text-transform: uppercase;
  font-variant: small-caps;
  font-family: ${fonts.larsseit.bold};
  border-radius: 30px 30px 0 0;
`

const TagPumpkin = styled.div`
  background-color: ${colors?.lightBlue};
  text-align: center;
  padding: 13px 0;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.navy};
  text-transform: uppercase;
  font-variant: small-caps;
  font-family: ${fonts.larsseit.bold};
  border-radius: 30px 30px 0 0;
`

const TagSkin = styled.div`
  background-color: ${colors?.yellow};
  text-align: center;
  padding: 13px 0;
  font-size: 20px;
  font-weight: 700;
  color: ${colors.navy};
  text-transform: uppercase;
  font-variant: small-caps;
  font-family: ${fonts.larsseit.bold};
  border-radius: 30px 30px 0 0;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Price = styled(H6)`
  span {
    color: ${colors.slate};
    font-size: 24px;
    text-decoration: line-through;
  }
`

const Card: React.FC = ({
  image,
  product,
  packType,
  details,
  ctaText = '   Buy Now   ',
  specialText,
  name = 'Single Tin',
  isSubscription,
  colors,
  handleAddToCart,
  isBundle,
  slug,
  idx
}) => {
  const priceType = isSubscription ? 'subscription' : 'oneTime'
  const { price = 0, compareAtPrice } = product[packType]?.pricing?.[priceType] || {}
  const perPrice = (price / 90 / (packType === 'multipack_2' ? 2 : 1)).toFixed(2)
  const outOfStock = price === 0

  return (
    <ProductCard isBundle={isBundle}>
      {specialText &&
        slug !== 'pumpkin-plus' &&
        slug !== 'skin-hero' &&
        slug !== 'bone-broth-plus' && (
          <Tag bg={slug !== 'paw-hero' ? colors?.bg : colors?.lightBlue} isBundle={isBundle}>
            {specialText}
          </Tag>
        )}
      {specialText && slug === 'skin-hero' && <TagSkin>{specialText}</TagSkin>}
      {specialText && slug === 'pumpkin-plus' && <TagPumpkin>{specialText}</TagPumpkin>}
      <ProductCardContent>
        <Price>
          {name}
          <br />${isSubscription ? price?.toFixed(2) : price}{' '}
          {compareAtPrice && <span>${compareAtPrice}</span>}
        </Price>
        {slug !== 'paw-hero' &&
          slug !== 'pumpkin-plus' &&
          slug !== 'skin-hero' &&
          slug !== 'bone-broth-plus' && <GreyText margin={'0 0 10px'}>${perPrice}/chew</GreyText>}
        <Center>
          {slug === 'paw-hero' || slug === 'pumpkin-plus' || slug === 'skin-hero' ? (
            <GatsbyImage
              image={getImage(image)}
              alt={name}
              style={{
                width: idx == 1 ? '580px' : '180px'
              }}
              imgStyle={{ objectFit: 'contain' }}
            />
          ) : (
            <GatsbyImage
              image={getImage(image)}
              alt={name}
              style={{
                width: packType === 'multipack_2' && !isBundle ? '580px' : '180px'
              }}
              imgStyle={{ objectFit: 'contain' }}
            />
          )}
          <ul>{details && details.map((detail) => <li key={detail}>{detail}</li>)}</ul>
          <CtaButtons
            brandColors={colors}
            slug={slug}
            outOfStock={outOfStock}
            handleAddToCart={handleAddToCart}
            hasSubscriptionVariant={true}
            isSubscription={true}
            ctaText={ctaText}
          />
          <GreyText margin={'10px 0 0'}>60 Day Money-Back Guarantee</GreyText>
        </Center>
      </ProductCardContent>
    </ProductCard>
  )
}

const BuyOptions: React.FC = ({ data }) => {
  let { slug, colors, outOfStock, buyOptions, isBundle } = data
  const { loading, checkoutId, setStore, products, checkout, actualTotal } = useCart()
  const [frequency, setFrequency] = useState(SUBSCRIPTION_FREQUENCIES[2])

  slug = slug === 'allergyanditch' ? 'allergy-and-itch' : slug

  const productsBySlug = products?.bySlug
  const product = productsBySlug ? productsBySlug[slug] : null

  const handleAddToCart = (option) => {
    const priceType = option?.isSubscription ? 'subscription' : 'oneTime'
    const variant = product[option?.packType]
    const price = variant?.pricing[priceType]?.price || 0
    const activeVariantId = variant?.variantId.encoded

    if (!loading && checkoutId && activeVariantId) {
      const selectedFrequency = option.isSubscription ? frequency : 0
      const subscription = option?.isSubscription ? variant?.pricing?.subscription : null

      const detail = {
        checkout,
        productMap: products,
        variantId: activeVariantId,
        quantity: 1,
        frequency: selectedFrequency,
        setStore,
        subscription,
        actualTotal,
        unitPrice: Number(price)
      }
      addVariantToCart(detail)
      trackAddToCartEvent(
        products,
        slug,
        variant?.variantId.decoded,
        Number(price),
        selectedFrequency
      )
    }
  }

  if (!loading) {
    return (
      <Section bg="navy">
        <Container>
          <ProductContainer isBundle={isBundle}>
            {buyOptions &&
              buyOptions.map((option, idx) => {
                return (
                  <Card
                    key={option.title}
                    image={option.image}
                    name={option.title}
                    packType={option?.packType}
                    product={product}
                    details={option.details}
                    ctaText={`   ${option.ctaText}   `}
                    colors={colors}
                    isSubscription={option.isSubscription}
                    specialText={option.specialText}
                    checkoutId={checkoutId}
                    products={products}
                    isBundle={isBundle}
                    slug={slug}
                    idx={idx}
                    handleAddToCart={() => handleAddToCart(option)}
                  />
                )
              })}
          </ProductContainer>
        </Container>
      </Section>
    )
  }

  return null
}

export default BuyOptions
