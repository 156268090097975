import React, { useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import { Headline, LargeP, P, H1, H6 } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'



const Section = styled.section<{ bg: string }>`
  width: 100%;
  background-color: ${props => colors[props.bg]};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 0;
  `}
  ${media.tablet`
    padding: 86px 0;
  `}
  ${media.desktop`
    padding: 86px 0;
    max-width: 100%;
  `}

  hgroup {
    max-width: 1200px;
    text-align: center;
    margin: 0 auto 36px;
    padding: 0 15px;
    white-space: pre-line;
    color: ${colors.navy};

    h1 {
      max-width:800px;
      margin: 0 auto;
    }

    p {
      padding: 20px 0 0 0;
    }
  }
`

const Header = styled(H1)`
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 800px;
  `}
`
const query = graphql`
  query {
    nascDesktop: file(relativePath: { regex: "/pdp/nasc-desktop/" }) {
      childImageSharp {
        gatsbyImageData(width: 880, quality: 100)
      }
    }
    nascMobile: file(relativePath: { regex: "/pdp/nasc-mobile/" }) {
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100 )
      }
    }
  }
`


const BoldLargeP = styled(LargeP)`
  font-family: ${fonts.larsseit.bold};
  padding: 61px 30px 0;
  max-width: 800px;
  text-align: center;
  color: ${colors.navy};
  ${media.mobile`
    display: none;
  `}
  ${media.desktop`
    display: block;
  `}
`




const NascSeal: React.FC = () => {
  const images = useStaticQuery(query)
  const responsiveImg = withArtDirection(getImage(images.nascDesktop), [
    {
      media: "(max-width: 600px)",
      image: getImage(images.nascMobile),
    },
  ])

  return (
    <Section bg="white">
      <Container>
        <hgroup>
          <Header>The NASC Quality Seal</Header>
          <H6>Certified through a comprehensive 3rd party audit and ongoing compliance with rigorous NASC quality standards.</H6>
        </hgroup>
        <GatsbyImage
          image={responsiveImg}
          alt="nasc-seal"
          layout="fullWidth"
          objectFit="contain"
        />
        <BoldLargeP>The NASC Quality Seal identifies products from companies that are committed to quality, vigilance, and continuous improvement.</BoldLargeP>
      </Container>
    </Section>
  )
}

export default NascSeal
