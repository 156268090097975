import React from 'react'
import styled from 'styled-components'
import DynamicStars from '../shared/DynamicStars'
import { colors } from '../../styles/variables'
import { MonoP } from '../shared/text'
import { media } from '../../styles/util'

interface ReviewsAndRatingsProps {
  reviewStats: {
    averageRating: number
    totalReviewCount: number
  }
}

const OrangeMonoP = styled(MonoP)`
  color: ${colors.orange};
  margin-left: 12px;
`

const TotalReviews = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`
const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  ${media.tablet`
    justify-content: flex-start;
  `}
`

const TotalReviewsAndRatings: React.FC<ReviewsAndRatingsProps> = ({ reviewStats }) => (
  <Container>
    <TotalReviews>
      <DynamicStars color={colors.orange} fontSize={16} rating={reviewStats.averageRating} />
      <OrangeMonoP>
        {reviewStats.totalReviewCount} {reviewStats.totalReviewCount > 1 ? 'reviews' : 'review'}
      </OrangeMonoP>
    </TotalReviews>
  </Container>
)
export default TotalReviewsAndRatings
