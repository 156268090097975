import React from 'react'
import styled from 'styled-components'
import { StarFilled, StarOutlined } from '@ant-design/icons'

const Container = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.fontSize}px;
  padding-bottom: 3px;
`

const SpacedStar = styled(StarFilled)`
  margin-right: 5px;
  &:last-of-type {
    margin: 0;
  }
`
const SpacedEmptyStar = styled(StarOutlined)`
  margin-right: 5px;
  &:last-of-type {
    margin: 0;
  }
`

interface FiveStarProps {
  color: string
  fontSize: number
  rating: number
}

const DynamicStars: React.FC<FiveStarProps> = ({ color, fontSize, rating }) => {
  const santizedRating = isNaN(rating) ? 0 : rating;
  const filledStars = Math.min(5, Math.round(santizedRating));
  const emptyStars = 5 - filledStars
  const filledStarsArr = new Array(filledStars)
  const emptyStarsArr = new Array(emptyStars)

  return (
    <Container color={color} fontSize={fontSize}>
      {Array.from(filledStarsArr).map((_, idx) => (
        <SpacedStar key={idx} />
      ))}
      {Array.from(emptyStarsArr).map((_, idx) => (
        <SpacedEmptyStar key={idx} />
      ))}
    </Container>
  )
}

export default DynamicStars
