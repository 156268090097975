import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Headline, LargeP, P, H1 } from '../shared/text'
import Carousel from '../shared/Carousel'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import { Section } from './ProductDetail'
import Quote from 'src/data/products/images/pumpkin-plus/quote.svg'
import QualityImage from 'src/data/products/images/pumpkin-plus/quality.png'




const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 30px 0;
    max-width: 350px;
    img.quality {
      max-width: 80px;
      width: 100%;
    }
  `}
  ${media.tablet`
    padding: 86px 0;
  `}
  ${media.desktop`
    padding: 86px 0;
    max-width: 1300px;
    img.quality {
      max-width: 303px;
      width: 100%;
    }
  `}
`

const Text = styled(H1)`
  color: #fff;
  position: relative;

    ${media.mobile`
      font-size: 13px;
      padding: 20px;
    `}

    ${media.desktop`
      font-size: 50px;
      padding: 60px 50px 50px 80px;
    `}


  img {
    position: absolute;

    ${media.mobile`
        width: 20px;
    `}

    ${media.desktop`
        width: 68px;
    `}

    &:first-of-type {
      top: 0;
      left: 0;
    }
    &:last-of-type {
      bottom: 0;
      right: 0;
      transform: rotate(180deg);
    }
  }
`

const Quality: React.FC = ({ quality }) => {


  return (
    <Section bg={"navy"}>
      <Container>
          <Text>
            <img src={Quote} />
            {quality}
            <img src={Quote} />
          </Text>
          <img className='quality' src={QualityImage} />
      </Container>
    </Section>
  )
}

export default Quality
