import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import BeforeImg from '../../data/products/images/paw-hero/before.png'
import AfterImg from '../../data/products/images/paw-hero/after.png'
import DogPalm from '../../data/products/images/paw-hero/dog-palm.svg'
import DogSnow from '../../data/products/images/paw-hero/dog-snow.svg'


const Section = styled.section`
  width: 100%;
  background-color: ${colors.tan};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 60px 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 26px 20px 80px 20px;
    max-width: 1200px;
  `}
  color: ${colors.navy};
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1334px;
  `}
`
const Grid = styled.div`
  display: grid;
  position: relative;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 20px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    padding: 60px 0 0px;
    grid-gap: 50px 180px;
  `}

  h6 {
    text-align: center;
    color: ${colors.navy};
    font-size: 55px;
    line-height: 57px;
    font-family: ${fonts.athletics.medium};
    max-width: 450px;
    margin: 0 auto 50px;
  }

  h4 {
    text-align: center;
    color: ${colors.navy};
    font-family: ${fonts.larsseit.medium};
    ${media.mobile`
      grid-area: 4 / span 1;
      font-size: 22px;
    `}
    ${media.desktop`
      grid-area: 2 / span 2;
      font-size: 42px;
    `}
  }
`



const DogHero = () => {

  return (
    <Section>
      <Container>
        <Grid>
          <div>
            <h6>from heat, dirt, and allergies</h6>
            <img src={DogPalm} alt="Dog Palms" />
          </div>
          <div>
            <h6>to snow, ice,<br/>and salt</h6>
            <img src={DogSnow} alt="after" />
          </div>
          <h4>everyday armor for&nbsp;adventure-ready paws</h4>
        </Grid>
      </Container>
    </Section>
  )
}

export default DogHero;
