import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'

import { media } from '../../styles/util'
import { colors, fonts } from '../../styles/variables'

const { Panel } = Collapse

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${colors.white};
  border-radius: 25px;
  & * {
    border: none !important;
  }
  ${media.mobile`
    padding: 10px 20px;
  `}
  ${media.tablet`
    padding: 30px;
  `}
  ${media.desktop`
    padding: 40px 42px 20px 42px;
  `}
`

const QuestionRow = styled(Panel)`
  width: 100%;
  border-top: 1px solid ${colors.lightText} !important;
  background-color: ${colors.white};
  &:first-of-type {
    border-top: none !important;
  }

  &.ant-collapse-item > .ant-collapse-header {
    min-height: 84px;
    color: ${colors.navy} !important;
    font-family: ${fonts.larsseit.regular};
    font-size: 18px;
    width: 100%;
    position: relative;
    outline: none;
    cursor: pointer;
    padding-right: 30px;
    line-height: 1.4;
    span {
      position: absolute;
      top: 20px;
      margin: 0;
    }
  }

  .ant-collapse-content {
    ${media.tablet`
      margin-top: -30px;
    `}
    width: 100%;
    color: ${colors.navy};
    padding-bottom: 30px;
    .ant-collapse-content-box {
      font-family: ${fonts.larsseit.light};
      line-height: 24px;
      font-size: 16px;
      padding: 0 40px 0 12px;
      white-space: pre-line;
    }
    & ul {
      padding-left: 30px;
    }
  }

  .ant-collapse-content-inactive {
    will-change: display;
    display: none;
  }
`

const ExpandIcon = styled(PlusOutlined)`
  & svg {
    height: 12px;
    width: 12px;
    fill: ${colors.navy};
  }
`

const CloseIcon = styled(MinusOutlined)`
  & svg {
    height: 12px;
    width: 12px;
    fill: ${colors.navy};
  }
`

interface QuestionProps {
  question: string
  answer: string
  answerToHtml?: boolean
  bullets?: string[]
}

const FaqGroup = ({ questionList }: { questionList: QuestionProps[] }) => {
  const [activeKey, setActiveKey] = useState('')
  return (
    <Container>
      <Collapse accordion onChange={setActiveKey}>
        {questionList.map(({ question, answer, answerToHtml, bullets }, idx) => {
          const open = activeKey === idx.toString()
          return (
            <QuestionRow header={question} key={idx.toString()} showArrow={false} extra={open ? <CloseIcon /> : <ExpandIcon />}>
              {!answerToHtml ? answer : <div dangerouslySetInnerHTML={{ __html: answer }} />}
              {bullets && (
                <ul>
                  {bullets.map(bullet => (
                    <li key={bullet}>{bullet}</li>
                  ))}
                </ul>
              )}
            </QuestionRow>
          )
        })}
      </Collapse>
    </Container>
  )
}

export default React.memo(FaqGroup)
