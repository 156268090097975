import React from 'react'
import styled from 'styled-components'
import { colors, fonts } from 'src/styles/variables'
import { media } from 'src/styles/util'

import { IQuantityButtonProps } from 'src/types/pdp'

const RelativeWrapper = styled.div`
  position: relative;
`

const Button = styled('div')<{ active: boolean }>`
  background-color: ${colors.white};
  border: ${(props) => (props.active ? `3px solid ${colors.navy}` : `1px solid ${colors.navy}`)};
  border-radius: 8px;
  cursor: pointer;

  width: 100%;

  display: grid;
  place-items: center;
  text-align: center;
  transition: 100ms all ease;
  height: 45px;
  position: relative;

  &:hover {
    border: 2px solid ${colors.navy};
  }

  ${media.mobile`
    height: 52px;
  `}

  & p {
    margin: 0;
    font-size: 18px;
    font-family: ${fonts.athletics.medium};
    color: ${colors.navy};
  }
`

const DiscountBadge = styled.div`
  width: 47px;
  height: 15px;
  border-radius: 5px;
  background-color: ${colors.navy};
  position: absolute;
  top: -6px;
  display: grid;
  place-items: center;
  transition: 100ms all ease;
  cursor: pointer;

  font-size: 10px;
  font-family: ${fonts.athletics.medium};
  border: none;
  color: #fff;
  line-height: 1;

  ${media.mobile`
    right: 16px;
  `}
  ${media.tablet`
    right: 9px;
  `}
`

const QuantityButton: React.FC<IQuantityButtonProps> = (props) => {
  const { activeVariant, packQuantity, onClick, option } = props

  const price = option.pricing.oneTime.price
  const compareAtPrice = option.pricing.oneTime.compareAtPrice
  const discount = compareAtPrice - price

  const active = option.variantId.encoded === activeVariant.variantId.encoded

  return (
    <RelativeWrapper>
      <Button active={active} onClick={onClick}>
        <p>{packQuantity}-Pack</p>
      </Button>
      {discount > 0 && <DiscountBadge>Save ${discount}</DiscountBadge>}
    </RelativeWrapper>
  )
}

export default QuantityButton
