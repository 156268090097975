import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import BeforeImg from '../../data/products/images/paw-hero/before.png'
import AfterImg from '../../data/products/images/paw-hero/after.png'
import DogCape from '../../data/products/images/paw-hero/dog-cape-yellow.svg'
import DogCapeStand from '../../data/products/images/paw-hero/dog-cape-stand.svg'


const Section = styled.section`
  width: 100%;
  background-color: ${colors.skyBlue};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 0 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px 0 20px;
    max-width: 1200px;
  `}
  color: ${colors.navy};
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto 50px;
  white-space: pre-line;
  ${media.mobile`
    //font-size: 50px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1334px;
  `}
`
const Grid = styled.div`
  display: grid;
  position: relative;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 20px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
    padding: 60px 0 120px;
    grid-gap: 180px;
  `}

  h6 {
    text-align: center;
    text-transform: uppercase;
    color: ${colors.navy};
    font-size: 18px;
    font-family: ${fonts.monosten.normal};
    margin-top: 20px;
  }
  & > div {
    img {
      border-radius: 26px;
      border: 2px solid ${colors.navy};
    }
  }
  .dog-cape, .dog-cape-mobile {
    ${media.mobile`
      max-width: 200px;
      margin: 0 auto;
    `}

    ${media.desktop`
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      z-index: 1;
      max-width: 100%;
    `}
  }

  .dog-cape {
    ${media.mobile`
      display: none;
    `}

    ${media.desktop`
      display: block;
    `}
  }

  .dog-cape-mobile {
    ${media.mobile`
      display: block;
    `}

    ${media.desktop`
      display: none;
    `}
  }
`



const Compare = () => {

  return (
    <Section>
      <Container>
        <Header>dry, weathered paws<br/>have met their match</Header>
        <Grid>
          <div>
            <img src={BeforeImg} alt="before" />
            <h6>before Paw Hero</h6>
          </div>
          <div>
            <img src={AfterImg} alt="after" />
            <h6>after daily Paw Hero use</h6>
          </div>
          <img className={"dog-cape"} src={DogCape} alt="dog cape" />
          <img className={"dog-cape-mobile"} src={DogCapeStand} alt="dog cape standing" />
        </Grid>
      </Container>
    </Section>
  )
}

export default Compare;
