import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PrimaryButton } from 'src/components/shared/buttons'
import { Headline, LargeP, P, H1, H6 } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import Checkmark from '../../assets/img/icons/checkmark-navy.svg'
import CheckmarkWhite from '../../assets/img/icons/checkmark-white.svg'
import scrollToTop from '../../utils/scrollToTop'
import { Section } from './ProductDetail'

const Container = styled.div<{ paragraphTextColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 0;
  `}
  ${media.tablet`
    padding: 86px 0;
  `}
  ${media.desktop`
    padding: 86px 0;
    max-width: 100%;
  `}

  hgroup {
    max-width: 100%;
    text-align: center;
    padding: 0 30px;
    white-space: pre-line;
    color: ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy};

    h1 {
      margin: 0 auto;
    }

    p {
      padding: 20px 0 0 0;
    }
    ${media.mobile`
      margin: 0 auto 50px;
    `}
    ${media.desktop`
      margin: 0 auto 100px;
    `}
  }
`


const BoldLargeP = styled(LargeP)<{ paragraphTextColor: string }>`
  font-family: ${fonts.larsseit.bold};
  font-size: 28px;
  line-height: 28px;
  color: ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy};
  margin: 0 0 30px 0;
  padding: 30px 0 0 0;
  ${media.mobile`
    text-align: center;
  `}
  ${media.desktop`
    text-align: inherit;
  `}
`

const Content = styled.div<{ paragraphTextColor: string }>`
  display: grid;
  grid-template-columns: auto 1fr;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 0;
  `}
  ${media.desktop`
    grid-template-columns: auto 1fr;
    grid-gap: 55px;
  `}

  aside {
    max-width: 600px;
    color: ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy} !important;



  ${media.mobile`
    margin: 0 23px;
    display: grid;
    place-items: center;

    & > div {
      padding: 30px 0;
      display: grid;
      grid-gap: 15px;
    }

  `}
  ${media.desktop`
    text-align: inherit;
    place-items: start;

    & > div {
      padding: 0;
      display: grid;
      grid-gap: 0;
    }

  `}

  }
`

const TextGroup = styled.div`
  width: 100%;
  display: grid;

  ${media.mobile`
    width:100%;
    place-items: center;
    grid-gap: 10px;
    max-width: 186px;
  `}
  ${media.desktop`
    place-items: start;
    text-align: left;
    grid-template-rows: min-content 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 26px;
    max-width: 100%;
  `}
`

const HeaderGrid = styled.div<{ paragraphTextColor: string }>`
  width: fit-content;

  display: grid;
  align-items: center;
  color: ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy};

  ${media.mobile`
    grid-template-columns: auto 1fr;
    grid-column-gap: 5px;
  `}
  ${media.desktop`
    grid-template-columns: auto 1fr;
    grid-row-gap: 20px;
    margin-left: 0;
  `}
`

const DifferentH6 = styled(H6)<{ paragraphTextColor: string }>`
  margin: 0 0 20px;
  padding: 30px 0 0 0;
  color: ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy};
  ${media.mobile`
    font-size: 36px;
    line-height: 46px;
    max-width: 180px;
    text-align: center;
    margin:0 auto;
  `}
  ${media.desktop`
   max-width: 100%;
   margin: 0 0 20px;
   padding: 30px 0 0 0;
   text-align: inherit;
  `}
`

const Subtext = styled(LargeP)<{ paragraphTextColor: string }>`
  max-width: 470px;
  color: ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy};
  ${media.mobile`
    padding-bottom: 0;
    text-align: center;
  `}
  ${media.desktop`
    padding-bottom: 30px;
    border-bottom: 1px solid ${props => props.paragraphTextColor ? colors[props.paragraphTextColor] : colors.navy};
  `}

`


const COPY_MAP = {
  'allergy-and-itch': [
    'NASC Certified',
    'Clean Ingredients, No Artificial Fillers',
    'Maximum Nutrition',
    'Sustainably Driven'
  ],
  'comfy-pup-bundle': [
    'NASC Certified',
    'Clean Ingredients, No Artificial Fillers',
    'Maximum Nutrition',
    'Sustainably Driven'
  ],
  default: [
    'Safety-First Manufacturing',
    'Quality Ingredients',
    'Tested & Approved',
    'Maximum Benefits',
  ]
}



const Guarantee: React.FC = ({ copy: { colors, guarantee, slug }, btnCopy, activeIndex}) => {
  const copy = COPY_MAP[slug] || COPY_MAP.default;


  const jumpTop = () => scrollToTop()

  return (
    <Section bg={colors?.bg} activeIndex={activeIndex}>
      <Container paragraphTextColor={guarantee?.paragraphTextColor}>
        <hgroup>
          <H1>Happiness Guarantee</H1>
        </hgroup>
        <Content>
          <GatsbyImage
            image={getImage(guarantee.image)}
            imgStyle={{ borderRadius: '40px' }}
            alt="corgi"
          />
          <aside>
            <BoldLargeP paragraphTextColor={guarantee?.paragraphTextColor}>Picky pup? No problem.</BoldLargeP>
            <Subtext paragraphTextColor={guarantee?.paragraphTextColor}>We've got you covered with a 60 day money-back guarantee if Fido doesn't love their Finn.</Subtext>
            <DifferentH6 paragraphTextColor={guarantee?.paragraphTextColor}>How We’re Different</DifferentH6>
            <div>
              {copy.map(item => (
                <TextGroup key={item}>
                  <HeaderGrid paragraphTextColor={guarantee?.paragraphTextColor}>
                    <img src={guarantee?.paragraphTextColor ? CheckmarkWhite : Checkmark} alt="Checkmark icon" />
                    <LargeP>{item}</LargeP>
                  </HeaderGrid>
                </TextGroup>
              ))}
            </div>
            <PrimaryButton color={guarantee?.textColor? guarantee?.textColor : 'white'} bg={guarantee?.buttonColor ? guarantee?.buttonColor : 'navy'} onClick={jumpTop} arrow copy={btnCopy} />
          </aside>
        </Content>
      </Container>
    </Section>
  )
}

export default Guarantee
