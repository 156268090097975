import React from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import SatisfactionGuarantee from '../../assets/img/badges/satisfaction-guarantee.svg'
import RiskFree from '../../assets/img/badges/risk-free.svg'
import MadeInUSA from '../../assets/img/badges/made-in-usa.svg'
import GmpFacility from '../../assets/img/badges/gmp-facility.svg'
import GrainFree from '../../assets/img/badges/grain-free.svg'
import NaturalIngredients from '../../assets/img/badges/natural-ingredients.svg'

const BadgesWrapper = styled.div`
  width: 100%;

  background-color: white;
  border-top: 1px solid ${colors.lightText};

  ${media.tablet`
    height: 120px;
  `}
`

const BadgeGrid = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  align-items: center;
  justify-items: center;

  ${media.mobile`
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  `}
  ${media.tablet`
    grid-template-columns: repeat(6, 1fr);
  `}
`

const BadgeImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${colors.lightText};

  &:last-of-type {
    ${media.tablet`
      border: none;
    `}
  }

  ${media.mobile`
    padding: 20px;
    border-bottom: 1px solid ${colors.lightText};
  `}
  ${media.tablet`
    padding: 0;
    border-bottom: none;
  `}
`

const BadgeImg = styled.img`
  height: auto;
  max-width: 80px;
  max-height: 55px;
  margin: 0 auto;
`

const Badges = () => {
  const badgeImages = [
    { src: SatisfactionGuarantee, alt: 'Satisfaction guarantee' },
    { src: RiskFree, alt: '100% risk free' },
    { src: MadeInUSA, alt: 'Made in the USA' },
    { src: GmpFacility, alt: 'Made in a GMP facility' },
    { src: GrainFree, alt: 'Grain free product' },
    { src: NaturalIngredients, alt: 'Natural ingredients' }
  ]

  return (
    <BadgesWrapper>
      <BadgeGrid>
        {badgeImages.map(badge => (
          <BadgeImgWrapper key={badge.alt}>
            <BadgeImg src={badge.src} alt={badge.alt} />
          </BadgeImgWrapper>
        ))}
      </BadgeGrid>
    </BadgesWrapper>
  )
}

export default Badges
