import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHover } from 'react-use'

import SubscriptionGuide from './SubscriptionGuide'
import { SmallP } from 'src/components/shared/text'
import { colors, fonts } from 'src/styles/variables'
import { media, getScreenType } from 'src/styles/util'
import { ISubscriptionGuideGroupProps } from 'src/types/pdp'

const CheckmarkLabel = styled(SmallP)`
  width: fit-content;
  text-align: left;
  font-size: 14px;
  line-height: 1.3 !important;
`

const Tooltip = styled.div`
  height: 17px;
  width: 17px;
  background-color: ${colors.slate};
  transition: 200ms background-color ease-in-out;
  color: ${colors.lightText};
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 13px;
  line-height: 1;
  margin: 0 9px 0 0;
  padding: 2px 0 0 0;
`

const SubGuideRow = styled.div`
  height: 100%;
  color: ${colors.slate};
  display: inline-block;
  cursor: pointer;
  transition: 200ms color ease-in-out;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  row-gap: 6px;
  border: none !important;

  width: fit-content;
  margin-bottom: 20px;

  &:hover {
    color: ${(props) => props.color};
    & ${Tooltip} {
      background-color: ${(props) => props.color};
    }
  }
  ${media.desktop`
    margin-bottom: 0;
    row-gap: 6px;
    grid-template-columns: 1fr;
    justify-items: start;
  `}
`

const SubGuideContainer = styled.div`
  align-self: start;
  justify-self: center;

  ${media.tablet`
    margin-left: 0px;
    justify-self: left;
    margin-left: 6px;
  `}
  ${media.desktop`
    padding-top: 17px;
  `}
`

const DogWeightLabel = styled(CheckmarkLabel)`
  display: inline-block;
`

const SubscriptionGuideGroup: React.FC<ISubscriptionGuideGroupProps> = (props) => {
  const { brandColors, isSubscription } = props
  const { bg, color } = brandColors

  const [subGuideVisible, setSubGuideVisible] = useState(false)

  const closeSubscriptionGuide = () => setSubGuideVisible(false)

  const SubscriptionGuideRow = () => (
    <SubGuideRow color={bg} onClick={() => setSubGuideVisible(true)}>
      <Tooltip>?</Tooltip>
      <DogWeightLabel>Dog Size & Weight Guide</DogWeightLabel>
    </SubGuideRow>
  )

  const [SubGuideRowEl, isHovering] = useHover(SubscriptionGuideRow)

  const isDesktop = getScreenType() === 'desktop'

  useEffect(() => {
    if (isDesktop) setSubGuideVisible(isHovering)
  }, [isDesktop, isHovering])

  useEffect(() => {
    window.addEventListener('scroll', closeSubscriptionGuide)
    return function cleanup() {
      window.removeEventListener('scroll', closeSubscriptionGuide)
    }
  }, [])

  return (
    <>
      <SubscriptionGuide
        visible={subGuideVisible}
        onClose={closeSubscriptionGuide}
        bg={colors[bg]}
        color={colors[color]}
      />

      <SubGuideContainer>{isSubscription && SubGuideRowEl}</SubGuideContainer>
    </>
  )
}

export default SubscriptionGuideGroup
