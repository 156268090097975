import React from 'react'
import styled from 'styled-components'

import { SmallP } from './text'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

import DotIcon from '../../assets/img/icons/dot.svg'

const DottedRow = styled.div`
  width: 100%;
  background: url(${DotIcon}) repeat-x bottom;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 500px;
`

const TransparentWrapper = styled.div`
  width: 100%;

  ${media.mobile`
    padding-right: 5px;
  `}
  ${media.tablet`
    padding-right: 9px;
  `}
`

const Text = styled(SmallP)<{ bg: string }>`
  width: fit-content;
  background-color: ${props => props.bg};
  line-height: 1 !important;
  margin-top: 14px;
  margin-bottom: -2px;
  white-space: pre-line;

  ${media.mobile`
    padding-right: 5px;
  `}
  ${media.tablet`
    padding-right: 9px;
  `}
`

const NoWrapText = styled(Text)`
  white-space: nowrap;
  text-align: right;

  ${media.mobile`
    padding-right: 0;
    padding-left: 5px;
  `}
  ${media.tablet`
    padding-right: 0;
    padding-left: 9px;
  `}
`

interface DottedTextInterface {
  left: string
  right: string
  bg?: string
}

const DottedText: React.FC<DottedTextInterface> = ({ left, right, bg = colors.white }) => (
  <DottedRow>
    <TransparentWrapper>
      <Text bg={bg}>{left}</Text>
    </TransparentWrapper>
    <NoWrapText bg={bg}>{right}</NoWrapText>
  </DottedRow>
)

export default DottedText
