import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import Arrow from 'src/data/products/images/pumpkin-plus/navy-arrow.svg'
import NoIcon from '../../assets/img/icons/icon-no.svg'

const Section = styled.section<{ bg: string }>`
  width: 100%;
  background-color: ${(props) => colors[props.bg]};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 60px 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px 120px 20px;
    max-width: 1400px;
  `}
  color: ${colors.white};
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  color: ${colors.white};
  ${media.mobile`
    font-size: 64px;
  `}
  ${media.desktop`
    font-size: 133px;
  `}
`

const Tabs = styled('div')`
  display: grid;
  justify-content: space-between;
  width: 100%;

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px 0;
  `}
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 2fr;
    padding: 80px 0;
  `}

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    grid-gap: 16px;

    ${media.mobile`
      display: flex;
      flex-direction: column;
    `}
    ${media.desktop`
      order: 0;
      display: grid;
      grid-template-columns: 1fr;
    `}

    li {
      font-family: ${fonts.athletics.medium};
      line-height: 1;
      cursor: pointer;
      display: flex;
      align-items: center;

      ${media.mobile`
        justify-content: center;
      `}

      ${media.desktop`
        justify-content: flex-start;
      `}

      img {
        width: 0;
        transition: all 0.4s;
        opacity: 0;

        ${media.mobile`
          display: none;
        `}

        ${media.desktop`
          display: inherit;
        `}
      }

      ${media.mobile`
        border: 1px solid ${colors.white};
        font-size: 18px;
        text-align: center;
        border-radius: 6px;
        padding: 8px 0;
      `}

      ${media.desktop`
        font-size: 64px;
        color: #ffffff;
        transition: all 0.4s;
        cursor: pointer;
        border: none;
        text-align: left;
      `}

      &.active {
        img {
          width: 77px;
          opacity: 1;
          margin: 0 20px 0 0;
        }

        ${media.mobile`
          background-color: ${colors.white};
          color: ${colors.orange};
        `}

        ${media.desktop`
          background-color: transparent;
          color: ${colors.navy};
        `}
      }
    }
  }

  .description {
    text-align: center;
    display: grid;

    ${media.mobile`
      align-items: start;
      justify-content: start;
    `}

    ${media.desktop`
      justify-content: center;
      align-items: center;
      text-align: center;
    `}

    & > div {
      grid-area: 1/1;
      opacity: 0;
      transition: all 0.4s;
      display: grid;
      width: fit-content;
      align-items: center;
      justify-content: center;

      ${media.mobile`
        grid-template-columns: 1fr;
        margin: 0;
        align-items: start;
        justify-content: start;
      `}

      ${media.desktop`
        grid-template-columns: auto 0.8fr;
        grid-gap: 0 40px;
      `}

      p {
        font-family: ${fonts.larsseit.normal};
        max-width: 547px;
        align-self: center;
        justify-self: center;
        color: #fff;

        ${media.mobile`
          padding: 20px 0 0;
          margin: 0;
          font-size: 14px;
          text-align: center;
        `}

        ${media.desktop`
          padding: 0;
          font-size: 24px;
          line-height: 40px;
          text-align: center;
        `}

        &.alt {
          text-align: center;
          font-family: ${fonts.monosten.normal};
          text-transform: uppercase;

          ${media.mobile`
            font-size: 8px;
          `}

          ${media.desktop`
            padding-top: 25px;
            font-size: 14px;
          `}
        }
      }
      &.active {
        opacity: 1;
      }
    }
  }
`

const Ingredients = ({ data }) => {
  const {
    ingredients: { title, items, nonIngredients },
    colors,
    slug
  } = data

  const [active, setActive] = useState(0)

  const onClick = (i) => {
    setActive(i)
  }

  return (
    <Section bg={slug === 'pumpkin-plus' ? 'orange' : 'salmon'}>
      <Container>
        <Header>{title}</Header>
        <Tabs>
          <ul>
            {items.map((item, i) => (
              <li onMouseEnter={() => onClick(i)} className={active === i ? 'active' : ''} key={i}>
                <img src={Arrow} alt="arrow" />
                {item.name}
              </li>
            ))}
          </ul>
          <div className="description">
            {items.map((item, i) => (
              <div key={i} className={active === i ? 'active' : ''}>
                <GatsbyImage
                  image={getImage(item.image)}
                  style={{
                    width: 'auto',
                    height: 'auto'
                  }}
                />
                <p dangerouslySetInnerHTML={{ __html: item.description }} />
                <p className="alt">{item.alt}</p>
              </div>
            ))}
          </div>
        </Tabs>
      </Container>
    </Section>
  )
}

export default Ingredients
