import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import BeforeImg from '../../data/products/images/skin-hero/before.png'
import AfterImg from '../../data/products/images/skin-hero/after.png'
import DogCape from '../../data/products/images/skin-hero/dog-cape-pink.svg'
import Stars from '../../data/products/images/skin-hero/stars.svg'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.yellow};
  overflow-x: hidden;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 100px 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px 0 20px;
    max-width: 1200px;
  `}
  color: ${colors.navy};
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto 50px;
  white-space: pre-line;
  ${media.mobile`
    //font-size: 50px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1334px;
  `}
`
const Grid = styled.div`
  display: grid;
  position: relative;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-gap: 20px;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
    padding: 60px 0 120px;
    grid-gap: 20px;
  `}

  h6 {
    text-align: center;
    text-transform: uppercase;
    color: ${colors.navy};
    font-size: 18px;
    font-family: ${fonts.monosten.normal};
    margin-top: 20px;
  }
  .dog-cape,
  .dog-cape-mobile {
    ${media.mobile`
      max-width: 200px;
      margin: 0 auto;
    `}

    ${media.desktop`
      position: absolute;
      right: -60px;
      bottom: calc(-100% - 80px);
      z-index: 1;
      max-width: 100%;
    `}
  }

  .dog-cape {
    ${media.desktop`
      display: block;
    `}
  }

  .testimonial {
    position: relative;
    .card {
      background-color: #fff;
      border-radius: 27px;
      text-align: center;

      ${media.mobile`
        display: block;
        padding: 30px;
      `}

      ${media.desktop`
        position: absolute;
        top: 50px;
        right: 50%;
        transform: translateX(45%);
        width: calc(100% + 50px);
        padding: 25px 50px;
      `}


      img {
        padding-bottom: 20px;

        &.dog-cape {
          ${media.mobile`
          position: absolute;
          bottom: -110px;
          right: -50px;
        `}
          ${media.desktop`
            position: absolute;
            right: -60px;
            bottom: calc(-100% - 40px);
            z-index: 1;
            max-width: 100%;
        `}
        }
      }
      p {
        line-height: 1.2;
        margin-bottom: 25px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`

const Compare = () => {
  return (
    <Section>
      <Container>
        <Header>Skin Hero to the Rescue!</Header>
        <Grid>
          <div>
            <img src={BeforeImg} alt="before" />
            <h6>Before skin hero</h6>
          </div>
          <div>
            <img src={AfterImg} alt="after" />
            <h6>After skin hero</h6>
          </div>
          <div className="testimonial">
            <div className="card">
              <img src={Stars} />
              <P>
                Our dog kept getting itchy spots that he wouldn’t stop licking. We decided to give
                Finn’s balm a try, and it actually worked! His hotspots have healed and he’s not
                scratching anymore.
                <br />
                So glad we found this!
              </P>
              <MonoP>– TERESA T</MonoP>
              <img className={'dog-cape'} src={DogCape} alt="dog cape" />
            </div>
          </div>
        </Grid>
      </Container>
    </Section>
  )
}

export default Compare
