import React from 'react'
import styled from 'styled-components'

import CheckMark from 'src/assets/img/icons/blue-checkmark.svg'
import { MonoP } from './text'
import { media } from '../../styles/util'

const VerifiedPurchaseContainer = styled.div`
  padding: 10px;
  background-color: #cadaf2;
  border-radius: 50px;
  display: flex;
  align-items: flex-end;

  ${media.desktop`
    margin-left: 20px;
  `};
`

const CheckMarkImg = styled.img`
  margin-bottom: 2px;
  margin-right: 4px;
`

export const VerifiedPurchaseBadge = () => {
  return (
    <VerifiedPurchaseContainer>
      <CheckMarkImg src={CheckMark} alt="check mark" />
      <MonoP>VERIFIED PURCHASE</MonoP>
    </VerifiedPurchaseContainer>
  )
}
