import React, { useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { gsap, Power3 } from 'gsap'
import { Collapse } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import Star from '../../assets/img/icons/star.svg'

import DottedText from '../shared/DottedText'
import { MonoP, P, SmallP, Headline } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import { usingNewPDP } from './index'

const { Panel } = Collapse

export const Card = styled('div')<{ tallCard?: boolean; bg?: string }>`
  overflow: hidden;
  position: relative;

  //background-color: ${props => props.bg || '#FFFFFF'};
  color: ${colors.navy};
  border-radius: 25px;

  margin: 0 auto;

  width: 100%;
  height: 100%;


  .ant-collapse-borderless {
    background-color: transparent;
  }
`

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
`

export const ActionLink = styled(MonoP)<{ right: string; left: string }>`
  color: ${colors.slate};
  position: absolute;
  bottom: 20px;
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  transition: 250ms color ease-in-out;
  cursor: pointer;

  &:hover {
    color: ${colors.navy};
  }
`

export const CopyContainer = styled('div')<{ isBundle: boolean; space: boolean; }>`
  height: 100%;
  padding: 30px 27px ${(props) => (props.isBundle ? '30px' : '30px')} 27px;

  display: ${(props) => (props.space ? 'flex' : 'block')};
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  justify-content: ${(props) => (props.space ? 'space-evenly' : 'flex-start')};
`

const IngredientsContainer = styled.div`
  ${media.mobile`
    margin-bottom: 30px;
  `}
  ${media.desktop`
    margin-bottom: 20px;
  `}
`

const IngredientsContainerAllery = styled(IngredientsContainer)`
  ${media.mobile`
    display: grid;
    grid-gap: 5px;
  `}
  ${media.desktop`
    margin-bottom: 20px;
  `}
`

const CardTitle = styled(MonoP)`
  margin-top: 10px;
  padding: 10px 0 20px 0;
  border-top: 1px solid ${colors.navy};
`

const Header = styled(P)<{ padding?: number }>`
  padding-top: ${(props) => props.padding || 10}px;
  font-family: ${fonts.larsseit.medium};

  &:first-of-type {
    padding-top: 0;
  }
`

const PanelItem = styled(Panel)`
  width: 100%;
  border: none !important;
  background-color: ${colors.white};
  margin: 20px 0 0 0;
  padding: 0;

  & * {
    border: none !important;
    margin: 0;
    padding: 0;
    color: ${colors.navy};
  }

  &.ant-collapse-item > .ant-collapse-header {
    width: 100%;
    position: relative;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 30px 0 0 !important;
    border-top: 1px solid ${colors.navy} !important;
  }

  .ant-collapse-content-box {
    width: 100%;
    border: none !important;
    margin: 0 0 0 0;
    padding: 0 20px 0 0 !important;
  }

  .ant-collapse-content-inactive {
    will-change: display;
    display: none;
  }
`

const AllergyPanel = styled(PanelItem)`
  &.ant-collapse-item > .ant-collapse-header {
    width: 100%;
    position: relative;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 0 !important;
    border-top: 0px !important;
  }
`

const ExpandIcon = styled(PlusOutlined)`
  & svg {
    height: 12px;
    width: 12px;
    fill: ${colors.navy};
  }
`

const CloseIcon = styled(MinusOutlined)`
  & svg {
    height: 12px;
    width: 12px;
    fill: ${colors.navy};
  }
`

const ExpandIconAllergy = styled(PlusOutlined)`
  & svg {
    height: 18px;
    width: 18px;
    margin: 10px 0 0;
    fill: ${colors.navy};
  }
`

const CloseIconAllergy = styled(MinusOutlined)`
  & svg {
    height: 18px;
    width: 18px;
    margin: 10px 0 0;
    fill: ${colors.navy};
  }
`


const BigArrow = styled.span`
  font-size: 20px;
`


const Feature = styled(Header)<{ color: string }>`
  font-size: 30px;
  line-height: 38px;
  padding-bottom: 15px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  &:before {
    content: '●';
    font-size: 25px !important;
    margin: 0 2px 0 0;
    color: ${props => colors[props.color]};
  }
`

const LargeText = styled(Header)`
  font-size: 30px;
  line-height: 38px;
  padding: 0 0 15px 0;
`

const StarContainer = styled.div`
  display: flex;
  gap: 3px;
  justify-content: center;
`

const TestHeader = styled(Headline)`
  font-family: ${fonts.athletics.medium};
  font-size: 30px;
  line-height: 1;
  padding: 8px 0;
`

const TestimonialContainer = styled.div`
  text-align: center;
  color: #fff;
  display: grid;
  grid-gap: 5px;
  margin:0 0 30px 0;
`

const LP = styled(P)`
  font-size: 20px;
  line-height: 23px;
  padding:0 0 15px 0;
`

const AllergySmallP = styled(SmallP)`
  text-align: center;
  font-size: 18px;
  line-height: 25px;
`

interface ImageProps {
  title: string
  src: any
  fullSizeSrc: any
  alt: string
  copy: {
    header: string
    body: string
    ingredients: {
      name: string
      amount: string
    }[]
  }[]
}

interface ProductCardProps {
  isBundle: boolean
  productSlug: string
  card: Array<ImageProps>
  cardKey: string
  color: string
  idx: number
}
const Testimonial: React.FC = ({ stars, headline, quote, name, location, rand }) => {
  return (
    <TestimonialContainer key={name}>
      <StarContainer>
        {Array(stars).fill(0).map(() => (
          <img src={Star} alt="Star icon" />
        ))}
      </StarContainer>
      <TestHeader>{headline}</TestHeader>
      <LP>“{quote}”</LP>
      <MonoP>-{name}</MonoP>
      <MonoP>-{location}</MonoP>
    </TestimonialContainer>
  )
}

const ProductCard: React.FC<ProductCardProps> = ({ isBundle, productSlug, card, cardKey, idx, color }) => {
  const [active, setActive] = useState(0)


  const handleChange = (idx: number) => {
    gsap.fromTo(`.${cardKey}`, { opacity: 1 }, { duration: 0.15, ease: Power3.easeOut, opacity: 0 })
    setTimeout(() => {
      setActive(idx)
    }, 200)
    gsap.fromTo(
      `.${cardKey}`,
      { opacity: 0 },
      { duration: 0.25, delay: 0.25, ease: Power3.easeIn, opacity: 1 }
    )
  }

  const titleCopy = card[active].title && card[active].title
  const cardCopy = card[active].copy && card[active].copy

  // Multivitamin needs a taller card bc of how many ingredients it has (and any bundles that
  // also include Multivitamin); Probiotics needs extra space too
  const tallCards = [
    'multivitamin',
    'super-pup-bundle',
    'super-pup-holiday',
    'active-pup-bundle',
    'chill-pup-bundle',
    'pretty-pup-bundle',
    'comfy-pup-bundle',
    'starter-bundle'
  ]
  const tallCard = tallCards.includes(productSlug)

  const [open, setOpen] = useState(false)
  const [openActive, setOpenActive] = useState(false)

  const activeIngredientCardImg = card[active].fullSizeSrc
  const cardImage = card[active].src
  const cardColor =  card[active]?.color;

  if (usingNewPDP.includes(productSlug)) {
    return (
      <Card tallCard={tallCard} bg={!isBundle ? colors[cardColor] : 'white'} key={cardKey}>
        <CardContent className={cardKey}>
          {!titleCopy && cardImage && !activeIngredientCardImg && (
            <GatsbyImage
              image={getImage(cardImage)}
              alt={card[active].alt}
              style={{
                width: '100%',
                maxHeight: 404,
                marginTop: 0
              }}
              imgStyle={{ objectFit: isBundle ? 'contain' : 'cover',
                borderTopLeftRadius: '25px',
                borderTopRightRadius: '25px'
               }}
            />
          )}
          <CopyContainer isBundle={isBundle}>
            {titleCopy && <CardTitle>{titleCopy}</CardTitle>}
            <div style={{ display: 'grid', gridRowGap: active === 2 ? 30 : 0 }}>
              {cardCopy &&
                cardCopy.map((textGroup) => {
                  const activeIngredients = textGroup.header === 'Active Ingredients Per Soft Chew'
                  const inactiveIngredients = textGroup.header === 'Inactive Ingredients'
                  if (activeIngredientCardImg) {
                    return (
                      <div key={textGroup.header}>
                        <Header>Active Ingredients Per Soft Chew</Header>
                        <GatsbyImage
                          image={getImage(activeIngredientCardImg)}
                          alt={card[active].alt}
                          style={{ margin: '30px 16px' }}
                          imgStyle={{ objectFit: 'contain' }}
                        />
                        <Collapse
                          key={textGroup.header}
                          defaultActiveKey={!tallCard ? '0' : undefined}
                          bordered={false}
                          onChange={() => setOpen((prevState) => !prevState)}
                        >
                          <AllergyPanel
                            key={textGroup.header}
                            header={<Header>{textGroup.header}</Header>}
                            showArrow={false}
                            extra={open ? null : <ExpandIconAllergy />}
                          >
                            <SmallP>{textGroup.body}</SmallP>
                          </AllergyPanel>
                        </Collapse>
                      </div>
                    )
                  }

                  if (activeIngredients && !isBundle) {
                    return (
                      <Collapse
                        key={textGroup.header}
                        defaultActiveKey={!tallCard ? '0' : undefined}
                        bordered={false}
                        onChange={() => setOpenActive((prevState) => !prevState)}
                      >
                        <AllergyPanel
                          key={textGroup.header}
                          header={<LargeText>{textGroup.header}</LargeText>}
                          showArrow={false}
                          extra={openActive ? <CloseIconAllergy /> : <ExpandIconAllergy />}
                        >
                          <IngredientsContainerAllery tallCard={tallCard}>
                            {textGroup.ingredients.map((ingredient) => (
                              <DottedText
                                key={ingredient.name}
                                left={ingredient.name}
                                right={ingredient.amount}
                              />
                            ))}
                          </IngredientsContainerAllery>
                        </AllergyPanel>
                      </Collapse>
                    )
                  }

                  if (inactiveIngredients && !isBundle) {
                    return (
                      <Collapse
                        key={textGroup.header}
                        defaultActiveKey={!tallCard ? '0' : undefined}
                        bordered={false}
                        onChange={() => setOpen((prevState) => !prevState)}
                      >
                        <AllergyPanel
                          key={textGroup.header}
                          header={<LargeText>{textGroup.header}</LargeText>}
                          showArrow={false}
                          extra={open ? <CloseIconAllergy /> : <ExpandIconAllergy />}
                        >
                          <SmallP>{textGroup.body}</SmallP>
                        </AllergyPanel>
                      </Collapse>
                    )
                  }

                  if (!textGroup.ingredients && !textGroup.header) {
                    return (
                      <React.Fragment key={textGroup.body}>
                        <AllergySmallP>{textGroup.body}</AllergySmallP>
                      </React.Fragment>
                    )
                  }


                  if (activeIngredients && isBundle) {
                    return (
                      <React.Fragment key={textGroup.header}>
                        <div key={textGroup.header}>
                          <Header>{textGroup.header}</Header>
                          <IngredientsContainer tallCard={tallCard}>
                            {textGroup.ingredients.map((ingredient) => (
                              <DottedText
                                key={ingredient.name}
                                left={ingredient.name}
                                right={ingredient.amount}
                              />
                            ))}
                          </IngredientsContainer>
                        </div>
                      </React.Fragment>
                    )
                  }


                  if (textGroup.testimonials && !isBundle){
                    return (
                      <React.Fragment key={textGroup.testimonials}>
                        {textGroup.testimonials.map((testimonial, idx) => (
                          <Testimonial key={`test-${idx}`} {...testimonial} />
                        ))}
                      </React.Fragment>
                    )
                  }

                  return (
                    <React.Fragment key={textGroup.header}>
                      <Feature color={color}>{textGroup.header}</Feature>
                    </React.Fragment>
                  )

                })}
            </div>
          </CopyContainer>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card tallCard={tallCard}>
      <CardContent className={cardKey}>
        {!titleCopy && cardImage && !activeIngredientCardImg && (
          <GatsbyImage
            image={getImage(cardImage)}
            alt={card[active].alt}
            style={{
              width: '100%',
              maxHeight: 350,
              marginTop: isBundle || (cardImage && idx > 0) ? 40 : 0
            }}
            imgStyle={{ objectFit: isBundle ? 'contain' : 'cover' }}
          />
        )}
        <CopyContainer isBundle={isBundle}>
          <CardTitle>{titleCopy}</CardTitle>
          <div style={{ display: 'grid', gridRowGap: active === 2 ? 30 : 0 }}>
            {cardCopy &&
              cardCopy.map((textGroup) => {
                const activeIngredients = textGroup.header === 'Active Ingredients Per Soft Chew'
                const inactiveIngredients = textGroup.header === 'Inactive Ingredients'
                if (activeIngredientCardImg) {
                  return (
                    <div key={textGroup.header}>
                      <Header>Active Ingredients Per Soft Chew</Header>
                      <GatsbyImage
                        image={getImage(activeIngredientCardImg)}
                        alt={card[active].alt}
                        style={{ margin: '30px 16px' }}
                        imgStyle={{ objectFit: 'contain' }}
                      />
                      <Collapse
                        key={textGroup.header}
                        defaultActiveKey={!tallCard ? '0' : undefined}
                        bordered={false}
                        onChange={() => setOpen((prevState) => !prevState)}
                      >
                        <PanelItem
                          key={textGroup.header}
                          header={<Header>{textGroup.header}</Header>}
                          showArrow={false}
                          extra={open ? null : <ExpandIcon />}
                        >
                          <SmallP>{textGroup.body}</SmallP>
                        </PanelItem>
                      </Collapse>
                    </div>
                  )
                }

                if (activeIngredients) {
                  return (
                    <div key={textGroup.header}>
                      <Header>{textGroup.header}</Header>
                      <IngredientsContainer tallCard={tallCard}>
                        {textGroup.ingredients.map((ingredient) => (
                          <DottedText
                            key={ingredient.name}
                            left={ingredient.name}
                            right={ingredient.amount}
                          />
                        ))}
                      </IngredientsContainer>
                    </div>
                  )
                }

                if (inactiveIngredients) {
                  return (
                    <Collapse
                      key={textGroup.header}
                      defaultActiveKey={!tallCard ? '0' : undefined}
                      bordered={false}
                      onChange={() => setOpen((prevState) => !prevState)}
                    >
                      <PanelItem
                        key={textGroup.header}
                        header={<Header>{textGroup.header}</Header>}
                        showArrow={false}
                        extra={open ? <CloseIcon /> : <ExpandIcon />}
                      >
                        <SmallP>{textGroup.body}</SmallP>
                      </PanelItem>
                    </Collapse>
                  )
                }

                return (
                  <React.Fragment key={textGroup.header}>
                    <Header padding={20}>{textGroup.header}</Header>
                    <SmallP>{textGroup.body}</SmallP>
                  </React.Fragment>
                )
              })}
          </div>
        </CopyContainer>
        {isBundle && (
          <>
            {active === 0 && (
              <ActionLink left="auto" right="24px" onClick={() => handleChange(1)}>
                Benefits <BigArrow>￫</BigArrow>
              </ActionLink>
            )}
            {active === 1 && (
              <>
                <ActionLink left="24px" right="auto" onClick={() => handleChange(0)}>
                  <BigArrow>￩</BigArrow> Summary
                </ActionLink>
                <ActionLink left="auto" right="24px" onClick={() => handleChange(2)}>
                  Ingredients <BigArrow>￫</BigArrow>
                </ActionLink>
              </>
            )}
            {active === 2 && (
              <ActionLink left="24px" right="auto" onClick={() => handleChange(1)}>
                <BigArrow>￩</BigArrow> Benefits
              </ActionLink>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default ProductCard
