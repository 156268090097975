import React from 'react'
import styled from 'styled-components'

import QuantityButton from './QuantityButton'
import { media } from 'src/styles/util'

import { IPackSelectionProps } from 'src/types/pdp'

const Container = styled.div`
  width: 100%;

  display: grid;
  column-gap: 12px;

  ${media.mobile`
    grid-template-columns: repeat(2, 102px);
  `}
  ${media.mobile`
    grid-template-columns: repeat(2, 108px);
  `}
`

const getPackQuantity = (packType: string) => {
  const [, packQuantity = 1] = packType.split('_')
  return Number(packQuantity)
}

const PackSelection: React.FC<IPackSelectionProps> = (props) => {
  const { otpOptions, activeVariant, clickQuantityButton } = props

  if (!otpOptions || otpOptions?.length <= 1) return null

  return (
    <Container>
      {otpOptions.map((option) => {
        const onClick = () => clickQuantityButton(option.packType)
        const packQuantity = getPackQuantity(option.packType)
        return (
          <QuantityButton
            key={option.packType}
            option={option}
            activeVariant={activeVariant}
            packQuantity={packQuantity}
            onClick={onClick}
          />
        )
      })}
    </Container>
  )
}

export default PackSelection
