import React from 'react'
import styled from 'styled-components'

import ProductCard, { Card, CopyContainer } from './ProductCard'
import { Headline, H1, P } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import { ProductSwap } from '../shared/ProductSwap'

export const Section = styled.section<{ bg: string; activeIndex: number }>`
  width: 100%;
  flex: 1 0 100%;
  background-color: ${(props) => colors[props.bg]};
  transform: translateX(${(props) => (props.activeIndex > 0 ? -(props.activeIndex * 100) : 0)}%);
  //transition: transform 0.4s;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 60px 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px 60px 20px;
    max-width: 1400px;
  `}
  color: ${colors.navy};
`

const Header = styled(Headline)<{ textColor: string }>`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  color: ${(props: { textColor: string }) =>
    props.textColor ? colors[props.textColor] : colors.navy};
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1334px;
  `}
`

const HeaderP = styled(P)<{ padding?: number }>`
  padding-top: ${(props) => props.padding || 10}px;
  font-family: ${fonts.larsseit.medium};

  &:first-of-type {
    padding-top: 0;
  }
`

const FeatureP = styled(Header)`
  font-size: 30px;
  line-height: 38px;
  padding-bottom: 15px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 14px;
`

const Grid = styled('div')<{ cardCols: number }>`
  width: 100%;
  display: grid;
  place-items: start;
  align-content: stretch;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-top: 50px;
  `}
  ${media.tablet`
    margin-top: 50px;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.desktop`
    max-width: ${(props: { cardCols: number }) => (props.cardCols === 2 ? '900px' : '1305px')};
    grid-template-columns: repeat(${(props: { cardCols: number }) => props.cardCols}, 1fr);
    grid-gap: 28px;
    margin-top: 60px;
    grid-template-rows: auto;
  `}
`

const Feature = styled(Header)<{ color: string }>`
  font-size: 30px;
  line-height: 38px;
  padding-bottom: 15px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 14px;
  &:before {
    content: '●';
    font-size: 25px !important;
    margin: 0 2px 0 0;
    color: ${(props) => colors[props.color]};
  }
`

const KeyIngredients = styled('div')`
  padding-bottom: 8px;
  h1 {
    padding-bottom: 0;
  }

  p {
    padding-left: 30px;
    font-size: 16px;
    color: #161345;
    line-height: 1.3;
  }

  &:last-of-type {
    padding-bottom: 25px;
  }
`

const Ingredients = styled('div')`
  h3 {
    font-family: ${fonts.larsseit.medium};
    border-bottom: 2px solid #161345;
    font-size: 11px;
    text-transform: uppercase;
    font-style: normal;
    color: #161345;
    font-weight: bold;
    letter-spacing: 1px;
  }

  h6 {
    font-size: 11px;
    font-family: ${fonts.athletics.medium};
    color: #161345;
    white-space: pre-line;
    strong {
      font-family: ${fonts.larsseit.medium};
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px 14px;
    padding: 0;

    &.single-row {
      grid-template-columns: 1fr;
    }

    li {
      border-bottom: 1px solid #161345;
      display: flex;
      justify-content: space-between;

      p {
        margin: 0;
        font-family: ${fonts.athletics.medium};
        font-size: 11px;
        white-space: pre-line;
      }
    }
  }
`

const SimpleIngredients = styled('div')`
  position: relative;
  height: 100%;

  dl {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 30px;

    ${media.desktop`
        padding: 0 30px;
      `}

    dt {
      color: ${colors.navy};
      font-family: ${fonts.athletics.medium};
      display: inline-block;
      font-size: 34px;
      padding-bottom: 8px;
      text-align: center;
      border-bottom: 3px solid ${colors.navy};
      margin-bottom: 14px;
      width: 100%;

      ${media.mobile`
        font-size: 22px;
      `}
      ${media.desktop`
        font-size: 34px;
      `}
    }
    dd {
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      text-transform: capitalize;
      text-align: left;
      font-family: ${fonts.athletics.medium};

      ${media.mobile`
        font-size: 17px;
        img {
          width: 25px !important;
          height: 25px !important;
          margin-right: 20px;
        }
      `}
      ${media.desktop`
        font-size: 26px;
        img {
          width: 49px !important;
          height: 49px !important;
          margin-right: 20px;
        }
      `}
    }
  }
`

export const Swipe = styled('article')`
  display: flex;
  overflow: hidden;
`

interface ImageProps {
  alt: string
  copy: {
    header: string
    body: string
  }
}

interface CardInterface {
  src: any
  fullSizeSrc: any
  alt: string
  copy: {
    body: string
    header: string
    keyIngredients?: {
      name: string
      description: string
    }
    ingredients?: {
      amount: string
      name: string
    }
  }
}

interface ProductDetailProps {
  data: {
    slug: string
    isBundle: boolean
    oneLiner: string
    colors?: any
    card1: Array<CardInterface>
    card2: Array<CardInterface>
    card3: Array<CardInterface>
    card4?: Array<CardInterface>
  }
}
const ProductDetail: React.FC<ProductDetailProps> = ({
  data,
  bundles,
  activeIndex,
  setActiveBundle
}) => {
  const { slug, isBundle, oneLiner, card1, card2, card3, card4, colors, perChew } = data
  const cards = [card1, card2, card3, card4].filter(Boolean)

  const cardCols = cards.length % 2 === 0 ? 2 : 3

  if (bundles && bundles.length > 0) {
    return (
      <Swipe>
        {bundles.map((product, v) => {
          const cards = [product.card1, product.card2, product.card3, product.card4].filter(Boolean)
          const bundleCardCols = cards.length % 2 === 0 ? 2 : 3
          const hasKeyIngredients =
            product.card2[0]?.copy.filter((e) => e?.keyIngredients).length > 0

          return (
            <Section key={`Section-${v}`} bg={product?.colors?.bg} activeIndex={activeIndex}>
              <Container>
                <ProductSwap
                  bundles={bundles}
                  setActiveBundle={setActiveBundle}
                  activeIndex={activeIndex}
                />
                <Header
                  textColor={slug === 'pumpkin-plus' || slug === 'bone-broth-plus' ? 'white' : ''}
                >
                  {product.oneLiner}
                </Header>
                <Grid cardCols={bundleCardCols}>
                  <ProductCard card={product.card1} isBundle={true} productSlug={product.slug} />
                  <Card>
                    <CopyContainer space isBundle>
                      {product.card2[0].copy
                        .filter(
                          (n) =>
                            n.header !== 'Key Ingredients' &&
                            n.header !== 'Inactive Ingredients' &&
                            n.header !== 'Active Ingredients Per Soft Chew'
                        )
                        .map((f) => {
                          return (
                            <React.Fragment key={f.header}>
                              <Feature color={product.colors?.bg}>{f.header}</Feature>
                            </React.Fragment>
                          )
                        })}
                    </CopyContainer>
                  </Card>
                  {hasKeyIngredients ? (
                    <Card>
                      <CopyContainer space isBundle>
                        <div>
                          <FeatureP>Key Ingredients</FeatureP>
                          {product.card2[0]?.copy
                            .filter((e) => e?.keyIngredients)
                            .map((k) => {
                              return k.keyIngredients.map((m) => (
                                <React.Fragment key={m.name}>
                                  <KeyIngredients>
                                    <Feature
                                      color={colors?.highlight ? colors?.highlight : colors?.bg}
                                    >
                                      {m.name}
                                    </Feature>
                                    <p>{m.description}</p>
                                  </KeyIngredients>
                                </React.Fragment>
                              ))
                            })}
                        </div>
                        <div>
                          <Ingredients>
                            <h6>
                              <strong>Full Ingredients: </strong>
                              {product.card2[0].copy.map((f) => (
                                <React.Fragment key={f.header}>{f.body && f.body}</React.Fragment>
                              ))}
                            </h6>
                          </Ingredients>
                        </div>
                      </CopyContainer>
                    </Card>
                  ) : (
                    <Card>
                      <CopyContainer space isBundle>
                        <div>
                          <FeatureP>Active Ingredients Per Soft Chew</FeatureP>
                          <Ingredients>
                            <h3>
                              Active ingredients per soft chew ({perChew ? `${perChew}g` : '2.5g'})
                            </h3>
                            {product.card2[0].copy.map((f) => (
                              <React.Fragment key={f.header}>
                                {f.ingredients && (
                                  <ul key={f.header}>
                                    {f.ingredients.map((i) => (
                                      <li key={i.name}>
                                        <p>{i.name}</p>
                                        <p>{i.amount}</p>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </React.Fragment>
                            ))}
                          </Ingredients>
                        </div>
                        <div>
                          <Ingredients>
                            <h6>
                              <strong>Ingredients: </strong>
                              {product.card2[0].copy.map((f) => (
                                <React.Fragment key={f.header}>{f.body && f.body}</React.Fragment>
                              ))}
                            </h6>
                          </Ingredients>
                        </div>
                      </CopyContainer>
                    </Card>
                  )}
                </Grid>
              </Container>
            </Section>
          )
        })}
      </Swipe>
    )
  }

  const hasKeyIngredients = card2[0]?.copy.filter((e) => e?.keyIngredients).length > 0

  return (
    <Swipe>
      <Section bg={colors?.bg}>
        <Container>
          <Header textColor={slug === 'pumpkin-plus' || slug === 'bone-broth-plus' ? 'white' : ''}>
            {oneLiner}
          </Header>
          <Grid cardCols={cardCols}>
            <ProductCard card={card1} isBundle={true} productSlug={slug} />
            <Card>
              <CopyContainer space isBundle>
                {card2[0]?.copy
                  .filter(
                    (n) =>
                      n?.header !== 'Key Ingredients' &&
                      n?.header !== 'Inactive Ingredients' &&
                      n?.header !== 'Active Ingredients Per Soft Chew'
                  )
                  .map((f) => {
                    return (
                      <React.Fragment key={f?.header}>
                        <Feature color={colors?.highlight ? colors?.highlight : colors?.bg}>
                          {f?.header}
                        </Feature>
                      </React.Fragment>
                    )
                  })}
              </CopyContainer>
            </Card>
            {hasKeyIngredients ? (
              <Card>
                <CopyContainer space isBundle>
                  <div>
                    <FeatureP>Key Ingredients</FeatureP>
                    {card2[0]?.copy
                      .filter((e) => e?.keyIngredients)
                      .map((k) => {
                        return k.keyIngredients.map((m) => (
                          <React.Fragment key={m.name}>
                            <KeyIngredients>
                              <Feature color={colors?.highlight ? colors?.highlight : colors?.bg}>
                                {m.name}
                              </Feature>
                              <p>{m.description}</p>
                            </KeyIngredients>
                          </React.Fragment>
                        ))
                      })}
                  </div>
                  <div>
                    <Ingredients>
                      <h6>
                        <strong>Full Ingredients: </strong>
                        {card2[0].copy.map((f) => (
                          <React.Fragment key={f.header}>{f.body && f.body}</React.Fragment>
                        ))}
                      </h6>
                    </Ingredients>
                  </div>
                </CopyContainer>
              </Card>
            ) : slug === 'pumpkin-plus' || slug === 'bone-broth-plus' ? (
              <Card>
                <CopyContainer>
                  <SimpleIngredients>
                    {card3[0].copy.map((f) => (
                      <React.Fragment key={f.header}>
                        {f.ingredients && (
                          <dl>
                            {slug === 'pumpkin-plus' ? (
                              <dt>Only 5 Ingredients</dt>
                            ) : (
                              <dt>Only 6 Ingredients</dt>
                            )}
                            {f.ingredients.map((n, i) => (
                              <dd key={`idx-${i}`}>
                                <img
                                  width={49}
                                  height={49}
                                  src={
                                    slug === 'pumpkin-plus'
                                      ? require(`src/data/products/images/pumpkin-plus/${n.icon}.svg`)
                                          ?.default
                                      : require(`src/data/products/images/bone-broth-plus/${n.icon}.png`)
                                          ?.default
                                  }
                                  alt={n.name}
                                />{' '}
                                {n.name}
                              </dd>
                            ))}
                          </dl>
                        )}
                      </React.Fragment>
                    ))}
                  </SimpleIngredients>
                </CopyContainer>
              </Card>
            ) : (
              <Card>
                <CopyContainer space isBundle>
                  <div>
                    <FeatureP>Active Ingredients Per Soft Chew</FeatureP>
                    <Ingredients>
                      <h3>Active ingredients per soft chew ({perChew ? `${perChew}g` : '2.5g'})</h3>
                      {card2[0].copy.map((f) => (
                        <React.Fragment key={f.header}>
                          {f.ingredients && (
                            <ul className={slug === 'digestive-probiotics' ? 'single-row' : ''}>
                              {f.ingredients.map((n, i) => (
                                <li key={`idx-${i}`}>
                                  <p>{n.name}</p>
                                  <p>{n.amount}</p>
                                </li>
                              ))}
                            </ul>
                          )}
                        </React.Fragment>
                      ))}
                    </Ingredients>
                  </div>
                  <div>
                    <Ingredients>
                      <h6>
                        <strong>Ingredients: </strong>
                        {card2[0].copy.map((f) => (
                          <React.Fragment key={f.header}>{f.body && f.body}</React.Fragment>
                        ))}
                      </h6>
                    </Ingredients>
                  </div>
                </CopyContainer>
              </Card>
            )}
          </Grid>
        </Container>
      </Section>
    </Swipe>
  )
}

export default ProductDetail
