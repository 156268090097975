import React from 'react'
import styled from 'styled-components'

import DropHeader from '../shared/DropHeader'
import FaqGroup from '../shared/FaqGroup'
import { InternalLink } from '../shared/text'
import { PrimaryButton } from '../shared/buttons'
import { colors } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  height: fit-content;
  background-color: ${colors.lightText};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${media.mobile`
    padding: 0 10px 80px 10px;
  `}
  ${media.tablet`
    padding: 0 10px 100px 30px;
  `}
  ${media.desktop`
    padding: 0 10px 100px 40px;
  `}
  & ul {
    padding: 0 0 0 16px;
    margin-top: -12px;
  }
  & a {
    color: ${colors.orange};
  }
  & a:hover {
    font-weight: 900;
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  align-items: end;
  ${media.mobile`
    grid-template-columns: 1fr;
    padding: 40px 10px 10px 10px;
    justify-items: center;
    grid-row-gap: 40px;
  `}
  ${media.tablet`
    grid-template-columns: 1fr 177px;
    grid-column-gap: 30px;
  `}
  ${media.desktop`
    grid-column-gap: 80px;
    grid-template-columns: 1fr 300px;
    padding: 30px 40px 0 80px;
    max-width: 1305px;
    justify-items: end;
    margin-bottom: 0;
  `}
`

interface QuestionProps {
  question: string
  answer: string
}

interface FAQProps {
  questionList: QuestionProps[]
}

const FAQ: React.FC<FAQProps> = ({ questionList }) => {
  return questionList.length > 0 ? (
    <Section>
      <DropHeader title="FAQs" leftAlign />
      <Grid>
        <FaqGroup questionList={questionList} />
        <InternalLink to="/faq">
          <PrimaryButton copy="More FAQs" arrow />
        </InternalLink>
      </Grid>
    </Section>
  ) : null
}

export default FAQ
