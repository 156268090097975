import React, { Fragment, useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Section } from './ProductDetail'
import { ProductSwap } from '../shared/ProductSwap'

import { Headline, LargeP, P, H1, H6 } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 0;
  `}
  ${media.tablet`
    padding: 86px 0;
  `}
  ${media.desktop`
    padding: 86px 0 60px;
    max-width: 100%;
  `}

  hgroup {
    max-width: 1200px;
    text-align: center;
    margin: 0 auto 36px;
    padding: 0 30px;
    white-space: pre-line;
    color: ${colors.navy};

    h1 {
      max-width: 1200px;
      margin: 0 auto;
    }

    p {
      padding: 20px 0 0 0;
    }
  }
`

const Header = styled(H1)`
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 800px;
  `}
`


const Grid = styled.div`
  display: grid;
  color: ${colors.navy};

  dd {
    border-bottom: 1px solid #999;
    &:last-of-type {
      border-width: 0;
    }
  }

  ul {
    margin: 0;
  }

  ${media.mobile`
    grid-template-columns: 1fr;

    & > div {
      padding: 33px 23px;
    }

    dl {
      padding: 0 20px;
    }

    dd {
      padding: 0 0 20px;
      margin: 0 0 20px 0;
    }

  `}
  ${media.desktop`
    grid-template-columns: 500px 1fr;
    grid-gap: 63px;
    max-width: 1200px;
    dl {
      padding: 0;
    }

    dd {
      padding: 0 0 10px;
      margin: 0 0 10px;
    }
  `}
`

const BoldLargeP = styled(LargeP)`
  font-family: ${fonts.larsseit.medium};
  font-size: 18px;
  line-height: 1.2;
  font-weight: 700;
`


const ColorCard = styled('div')<{ bg: string; color: string }>`
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  width: 100%;
  align-self: end;
  text-align: center;
  padding: 24px;
`

const TextContainer = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`



const WhatToExpect: React.FC = ({ data, bundles, activeIndex, setActiveBundle }) => {
  const { expectations: { src, title, weeks, dosage, body }, colors: { color, bg } } = data;

  return (
    <Section bg={bg} activeIndex={activeIndex}>
      <Container>
        <hgroup>
          <Header>{title}</Header>
        </hgroup>
        <Grid>
          <GatsbyImage
            image={getImage(src)}
            alt="what to expect"
            objectFit="contain"
          />
          <TextContainer>
            {body ? body.map(text => (
              <H6 key={text}>{text}</H6>
            )
            ) : (
              <dl>
                {weeks && weeks.map((week) => (
                  <React.Fragment key={week.title}>
                    <dt><H6>{week.title}</H6></dt>
                    <dd>
                      <ul>
                        {week.phases.map(p => (
                          <li key={p.phase}><P>{p.bold ? (<strong>{p.phase}</strong>) : p.phase}</P></li>
                        ))}
                      </ul>
                    </dd>
                  </React.Fragment>
                ))}
              </dl>
            )}
            <ColorCard bg={'white'} color={'navy'}>
              <BoldLargeP>{dosage}</BoldLargeP>
            </ColorCard>
          </TextContainer>
        </Grid>
        {bundles && <ProductSwap bundles={bundles} setActiveBundle={setActiveBundle} activeIndex={activeIndex} />}
      </Container>
    </Section>
  )
}

export default WhatToExpect
