import React, { useRef } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Headline, LargeP, P, H1 } from '../shared/text'
import Carousel from '../shared/Carousel'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import { ProductSwap } from '../shared/ProductSwap'
import { Section } from './ProductDetail'

import RightArrow128 from 'src/assets/img/icons/right-cursor-128.png'
import RightArrow32 from 'src/assets/img/icons/right-cursor-32.png'
import LeftArrow128 from 'src/assets/img/icons/left-cursor-128.png'
import LeftArrow32 from 'src/assets/img/icons/left-cursor-32.png'
import Stars from 'src/data/products/images/pumpkin-plus/stars.svg'

import { IImageCarouselProps } from 'src/types/pdp'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 0;
  `}
  ${media.tablet`
    padding: 86px 0;
  `}
  ${media.desktop`
    padding: 86px 0;
    max-width: 100%;
  `}
`

const Header = styled(H1)<{ textColor: string }>`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  text-transform: capitalize;
  color: ${(props: { textColor: string }) => (props.textColor ? props.textColor : colors.navy)};
  ${media.mobile`
    max-width: 300px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    max-width: 1334px;
  `}
`
const Grid = styled('div')<{ cardCols: number }>`
  width: 100%;
  display: grid;
  place-items: center;
  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    margin-top: 50px;
  `}
  ${media.tablet`
    margin-top: 50px;
    grid-template-columns: 1fr 1fr;
  `}
  ${media.desktop`
    max-width: ${(props: { cardCols: number }) => (props.cardCols === 2 ? '900px' : '1305px')};
    grid-template-columns: repeat(${(props: { cardCols: number }) => props.cardCols}, 1fr);
    grid-gap: 28px;
    margin-top: 60px;
    grid-template-rows: auto;
  `}
`

const Card = styled('div')<{ tallCard?: boolean }>`
  overflow: hidden;
  position: relative;

  color: ${colors.navy};
  border-radius: 25px;
  background-color: #fff;

  width: 100%;
  height: 100%;

  ${media.mobile`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '500px' : '450px')};
  `}
  ${media.tablet`
    min-height: ${(props: { tallCard?: boolean }) => (props.tallCard ? '602px' : '510px')};
  `}
  ${media.desktop`
    min-height: 625px;
  `}

  .ant-collapse-borderless {
    background-color: transparent;
  }
`

const CardContent = styled.div`
  width: 100%;
  min-height: fit-content;
  display: grid;
  //align-content: space-between;
`

const CardWrapper = styled.div`
  padding: 0 20px;
`

const BoldP = styled(P)`
  font-family: ${fonts.larsseit.medium};
  ${media.mobile`
    margin-bottom: 6px;
  `}
  ${media.desktop`
    margin-bottom: 0;
  `}
`

const BoldLargeP = styled(LargeP)`
  font-family: ${fonts.larsseit.bold};
  line-height: 24px;
  margin-bottom: 13px;
`
const BodyP = styled.p`
  font-family: ${fonts.larsseit.regular};
  font-size: 16px;
  line-height: 20px;
`

const BodyPname = styled.p`
  font-family: ${fonts.monosten.normal};
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  justify-content: space-between;
  margin: 0;
  padding-top: 30px;
`

const SlideContainer = styled.div`
  padding: 80px 0 0;
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  position: relative;

  ${media.mobile`
    .slider {
      padding: 0 14px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 33px;
    }
  `}
`

const LeftClickBox = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: url(${LeftArrow128}) 128 100, url(${LeftArrow32}) 32 20, pointer;
`

const RightClickBox = styled(LeftClickBox)`
  left: auto;
  right: 0;
  cursor: url(${RightArrow128}) 128 100, url(${RightArrow32}) 32 20, pointer;
`

const CopyContainer = styled('div')`
  padding: 30px;
  height: 100%;

  .headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${BoldLargeP} {
      margin: 0;
    }
  }

  .desc {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 25px 0;
  }

  .dosage,
  .stats p:not(.age) {
    font-weight: bold;
    margin-bottom: 0;
  }

  .age {
    font-size: 8px;
    font-weight: bold;
    strong {
      font-size: 13px;
      padding: 0 2px 0 0;
    }
    span {
      display: inline-block;
      padding: 0 5px;
    }
  }
`

const ApprovedBy: React.FC = ({ data, bundles, activeIndex, setActiveBundle }) => {
  const {
    colors: pageColors,
    approvedBy: { title, owners, colors: approvedByColors },
    slug
  } = data
  const carouselRef = useRef(null)
  const handleNext = () => {
    if (carouselRef?.current) carouselRef.current.next()
  }

  const handlePrev = () => {
    if (carouselRef?.current) carouselRef.current.prev()
  }
  const responsive = [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]

  return (
    <Section
      bg={approvedByColors?.bg ? approvedByColors?.bg : pageColors?.bg}
      activeIndex={activeIndex}
    >
      <Container>
        <Header textColor={approvedByColors?.color}>{title}</Header>
        <SlideContainer>
          <LeftClickBox onClick={handlePrev} />
          <RightClickBox onClick={handleNext} />
          <Carousel
            ref={carouselRef}
            slidesToShow={4}
            autoPlay={true}
            infinite={false}
            responsive={responsive}
          >
            {owners.map((dog) => (
              <CardWrapper key={dog.name}>
                <Card tallCard={false}>
                  <CardContent>
                    <GatsbyImage
                      image={getImage(dog.src)}
                      style={{
                        width: '100%',
                        height: 300
                      }}
                      alt={dog.name}
                      imgStyle={{
                        objectFit: 'cover',
                        borderTopLeftRadius: '25px',
                        borderTopRightRadius: '25px'
                      }}
                    />
                    {slug === 'pumpkin-plus' || slug === 'bone-broth-plus' ? (
                      <CopyContainer>
                        <div className="headline">
                          <BoldLargeP>{dog.headline}</BoldLargeP>
                          <img src={Stars} />
                        </div>
                        <div className="desc">
                          <div className="stats">
                            <p>{dog.breed}</p>
                            <p className="age">
                              <strong>{dog.age}</strong> yrs old<span></span>
                              <strong>{dog.weight}</strong>lbs
                            </p>
                          </div>
                          <div className="dosage">{dog.dosage}</div>
                        </div>
                        <BodyP>{dog.description}</BodyP>
                        <BodyPname>
                          <span></span>
                          <span>- {dog.name}</span>
                        </BodyPname>
                      </CopyContainer>
                    ) : (
                      <CopyContainer>
                        <BoldLargeP>{dog.headline}</BoldLargeP>
                        <BodyP>{dog.description}</BodyP>
                        <BoldP>{dog.name}</BoldP>
                      </CopyContainer>
                    )}
                  </CardContent>
                </Card>
              </CardWrapper>
            ))}
          </Carousel>
        </SlideContainer>
        {bundles && (
          <ProductSwap
            bundles={bundles}
            setActiveBundle={setActiveBundle}
            activeIndex={activeIndex}
          />
        )}
      </Container>
    </Section>
  )
}

export default ApprovedBy
