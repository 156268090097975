import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import DottedText from './DottedText'
import { Headline, LargeP, P, MonoP } from './text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'

const Section = styled.section`
  width: 100%;
  background-color: ${colors.white};

  ${media.tablet`
    padding: 70px 30px 80px 0;
  `}
  ${media.desktop`
    padding: 86px 40px 160px 40px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;

  ${media.mobile`
    padding: 50px 20px 0 20px;
  `}
  ${media.tablet`
    padding: 0;
  `}
  ${media.desktop`
    max-width: 1100px;
  `}
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto;

  ${media.tablet`
    max-width: 400px;
  `}
  ${media.desktop`
    max-width: 600px;
  `}
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
  ${media.tablet`
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  `}
  ${media.desktop`
    margin-top: 80px;
    grid-gap: 60px;
  `}
`

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 467px;
  ${media.mobile`
    display: none;
  `}
  ${media.tablet`
    display: block;
  `}
`

const MobileImg = styled(ImgWrapper)`
  ${media.mobile`
    display: block;
    height: 376px;
  `}
  ${media.tablet`
    display: none;
  `}
`

const RightGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.mobile`
    padding: 0 0 50px 0;
    margin-top: 50px;
  `}
  ${media.tablet`
    height: 100%;
    padding: 0;
    margin-top: 0;
  `}
`

const RightTextGroup = styled.div`
  ${media.mobile`
    padding: 0px 10px;
  `}
  ${media.tablet`
    padding: 0 20px 0 10px;
  `}
  ${media.desktop`
    padding: 0;
  `}
`

const Directions = styled(MonoP)`
  border-top: 1px solid ${colors.navy};
  width: 100%;
  padding: 14px 0 30px 0;
`

const BoldP = styled(P)`
  font-family: ${fonts.larsseit.medium};
  ${media.mobile`
    margin-bottom: 6px;
  `}
  ${media.desktop`
    margin-bottom: 0;
  `}
`

const BoldLargeP = styled(LargeP)`
  font-family: ${fonts.larsseit.medium};
`

const ColorCard = styled('div')<{ bg: string; color: string }>`
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  width: 100%;
  border-radius: 25px;
  align-self: end;
  text-align: center;
  ${media.mobile`
    padding: 30px;
    margin-top: 50px;
  `}
  ${media.tablet`
    padding: 20px 36px;
  `}
  ${media.desktop`
    padding: 40px 55px;
  `}
`

const query = graphql`
  query {
    dosage: file(relativePath: { regex: "/pdp/dosage.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 467)
      }
    }
  }
`

interface DosageInterface {
  bg: string
  color: string
  oneLiner: string
  petName?: string
}

const Dosage: React.FC<DosageInterface> = ({ bg, color, petName, oneLiner }) => {
  const images = useStaticQuery(query)

  const rows: Array<Array<any>> = [
    ['1 - 25 lbs', 1],
    ['26 - 50 lbs', 2],
    ['51 - 75 lbs', 3],
    ['Over 75 lbs', 4]
  ]
  return (
    <Section>
      <MobileImg>
        <GatsbyImage
          image={getImage(images.dosage)}
          alt="Hand holding supplements"
          style={{ width: '100%', height: '100%' }}
          imgStyle={{
            objectFit: 'cover',
            backgroundPosition: 'bottom',
            backgroundRepeat: 'no-repeat'
          }}
        />
      </MobileImg>
      <Container>
        <Header>How often should I give this to {petName || 'my dog'}?</Header>
        <Grid>
          <ImgWrapper>
            <GatsbyImage
              image={getImage(images.dosage)}
              alt="Hand holding supplements"
              style={{ width: '100%', height: '100%' }}
            />
          </ImgWrapper>
          <RightGroup>
            <RightTextGroup>
              <Directions>Directions</Directions>
              <BoldP>Suggested Use:</BoldP>
              <P>Administer 1 soft chew daily per 25lbs of body weight.</P>
              <br />
              {rows.map((row) => (
                <DottedText
                  left={row[0]}
                  right={`${row[1]} Soft Chew${row[1] > 1 ? 's' : ''}`}
                  key={row[0]}
                />
              ))}
            </RightTextGroup>
            <ColorCard bg={bg} color={color}>
              <BoldLargeP>{oneLiner}</BoldLargeP>
            </ColorCard>
          </RightGroup>
        </Grid>
      </Container>
    </Section>
  )
}

export default Dosage
