import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Headline, LargeP, P, H1, H6, MonoP } from '../shared/text'
import { colors, fonts } from '../../styles/variables'
import { media } from '../../styles/util'
import Arrow from '../../assets/img/icons/long-arrow.svg'
import NoIcon from '../../assets/img/icons/icon-no.svg'

const Section = styled.section<{ bg: string }>`
  width: 100%;
  background-color: ${(props) => colors[props.bg]};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  ${media.mobile`
    padding: 50px 20px 60px 20px;
  `}
  ${media.tablet`
    padding: 86px 20px 60px 20px;
  `}
  ${media.desktop`
    padding: 86px 20px 120px 20px;
    max-width: 1200px;
  `}
  color: ${colors.navy};
`

const Header = styled(Headline)`
  text-align: center;
  margin: 0 auto;
  white-space: pre-line;
  ${media.mobile`
    font-size: 64px;
  `}
  ${media.tablet`
    max-width: 600px;
  `}
  ${media.desktop`
    font-size: 100px;
    max-width: 1334px;
  `}
`

const Tabs = styled('div')`
  display: grid;
  justify-content: space-between;
  width: 100%;
  padding: 80px 0;

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
  ${media.tablet`
    grid-template-columns: 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr;
  `}

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    grid-gap: 16px;

    ${media.mobile`
      display: grid;
      grid-template-columns: 1fr 1fr;
      order: 2;
    `}
    ${media.desktop`
      order: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 5px;
    `}

    li {
      font-family: ${fonts.athletics.medium};
      line-height: 1;
      cursor: pointer;
      display: flex;
      align-items: center;

      ${media.mobile`
        justify-content: center;
      `}

      ${media.desktop`
        justify-content: flex-start;
      `}

      img {
        width: 0;
        transition: all 0.4s;
        opacity: 0;

        ${media.mobile`
          display: none;
        `}

        ${media.desktop`
          display: inherit;
        `}

      }

      ${media.mobile`
        border: 1px solid ${colors.navy};
        font-size: 18px;
        text-align: center;
        border-radius: 6px;
        padding: 8px 0;
      `}

      ${media.desktop`
        font-size: 50px;
        color: #fff;
        transition: all 0.4s;
        cursor: pointer;
        border: none;
        text-align: left;
        padding: 0;
        line-height: 1;
      `}

      &.active {

        img {
          width: 77px;
          opacity: 1;
          margin: 0 20px 0 0;
        }

        ${media.mobile`
          background-color: ${colors.navy};
          color: #fff;
        `}

        ${media.desktop`
          background-color: transparent;
          color: ${colors.navy};
        `}

      }
    }
  }

  .description {
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    & > div {
      grid-area: 1/1;
      opacity: 0;
      transition: all 0.4s;
      p {
        font-family: ${fonts.larsseit.normal};
        font-size: 24px;
        line-height: 40px;
        max-width: 547px;

        ${media.mobile`
          padding: 50px 0 0;
          margin: 0;
        `}

        ${media.desktop`
          padding: 50px 0;
        `}

      }
      &.active {
        opacity: 1;
      }
  }
`

const NoIngredients = styled('div')`
  display: flex;
  background-color: ${colors.navy};
  border-radius: 10px;
  width: 100%;
  padding: 26px;
  align-items: center;

  ${media.mobile`
    flex-direction: column;
  `}

  ${media.desktop`
    gap: 80px;
    flex-direction: row;
  `}

  h5 {
    font-family: ${fonts.larsseit.bold};
    text-transform: uppercase;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    font-variant: all-small-caps;
    text-align: left;
    margin: 0;

    ${media.mobile`
      margin: 0 0 20px 0;
      text-align: center;
    `}

    ${media.desktop`
      text-align: left;
      margin: 0;
    `}
  }

  ul {
    list-style: none;
    display: grid;
    flex: 1;
    padding: 0;
    margin: 0;

    ${media.mobile`
      grid-template-columns: 1fr 1fr;
    `}

    ${media.desktop`
      grid-template-columns: repeat(4, 1fr);
    `}


    img {
      transform: translateY(2px);
    }

    li {
      display: flex;
      align-items: center;
      color: #fff;
      gap: 12px;
      font-size: 24px;
    }
  }
`

const Ingredients = ({ data }) => {
  const {
    ingredients: { title, items, nonIngredients },
    colors
  } = data

  const [active, setActive] = useState(0)

  const onClick = (i) => {
    setActive(i)
  }

  return (
    <Section bg={'pink'}>
      <Container>
        <Header>{title}</Header>
        <Tabs>
          <ul>
            {items.map((item, i) => (
              <li onMouseEnter={() => onClick(i)} className={active === i ? 'active' : ''} key={i}>
                <img src={Arrow} alt="arrow" />
                {item.name}
              </li>
            ))}
          </ul>
          <div className="description">
            {items.map((item, i) => (
              <div key={i} className={active === i ? 'active' : ''}>
                <GatsbyImage
                  image={getImage(item.image)}
                  style={{
                    width: '320px',
                    height: 'auto'
                  }}
                  imgStyle={{ objectFit: 'contain' }}
                />
                <p dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
            ))}
          </div>
        </Tabs>
        <NoIngredients>
          <h5>{nonIngredients.title}</h5>
          <ul>
            {nonIngredients.items.map((item, i) => (
              <li key={i}>
                <img src={NoIcon} alt="no icon" />
                {item}
              </li>
            ))}
          </ul>
        </NoIngredients>
      </Container>
    </Section>
  )
}

export default Ingredients
